import { Accordion, Box, CloseButton, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { IconMail, IconUserPlus } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import { Avatar } from '~/domains/common/components/Avatar/Avatar';

import type { Contest } from '../../services/interfaces/contest.interface';
import type { EntrantUser } from '../../services/interfaces/entrant.interface';
import { getContestThumbnail } from '../../common/utils/getContestThumbnail';

import { MOBILE_SECTION_HEIGHT, SECTION_HEIGHT } from './consts';

type InviteModalRightProps = {
  emailInvites: string[];
  setEmailInvites: Dispatch<SetStateAction<string[]>>;
  splashInvites: string[];
  setSplashInvites: Dispatch<SetStateAction<string[]>>;
  rypInvites: string[];
  setRYPInvites: Dispatch<SetStateAction<string[]>>;
  rypPools: string[];
  setRYPPools: Dispatch<SetStateAction<string[]>>;
  contests: string[];
  setContests: Dispatch<SetStateAction<string[]>>;
  emailOnly?: boolean;
  totalInvites: number;
};

function InviteModalRight({
  emailInvites = [],
  setEmailInvites,
  splashInvites,
  setSplashInvites,
  rypInvites,
  setRYPInvites,
  rypPools,
  setRYPPools,
  contests,
  setContests,
  emailOnly,
  totalInvites,
}: InviteModalRightProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });

  const handleRemoveFromList = (list, removedItem, callback) => {
    const newList = list.filter((item) => item !== removedItem);
    callback(newList);
  };

  const showEmptyState = totalInvites === 0;

  return (
    <Box
      w="40%"
      h={isTablet ? MOBILE_SECTION_HEIGHT : SECTION_HEIGHT}
      px="sm"
      sx={() => ({
        borderLeft:
          theme.colorScheme === 'dark'
            ? `1px solid ${theme.colors.gray[8]}`
            : `1px solid ${theme.colors.gray[4]}`,
      })}
    >
      <ScrollArea h="100%" styles={showEmptyState ? { root: { '& div': { height: '100%' } } } : {}}>
        <Stack h="100%" w="100%" spacing={2}>
          <Text size={18} fw={700}>
            {t('invites.modal.xSelected', {
              count: totalInvites,
            })}
          </Text>
          {showEmptyState && (
            <Stack h="100%" align="center" justify="center">
              <IconUserPlus />
              <Text size={14} align="center">
                {emailOnly
                  ? t('invites.modal.emailOnlyEmptyState')
                  : t('invites.modal.selectedEmptyState')}
              </Text>
            </Stack>
          )}
          {!showEmptyState && (
            <Accordion multiple>
              {emailInvites.length > 0 && (
                <Accordion.Item value="emailOnly">
                  <Accordion.Control>
                    <Text>
                      {t('invites.modal.emails__hasPlural', {
                        count: emailInvites.length,
                      })}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="sm">
                      {emailInvites.map((email) => (
                        <Group key={email} position="apart" noWrap>
                          <Group noWrap w={250}>
                            <IconMail color={theme.colors.gray[5]} />
                            <Text truncate>{email}</Text>
                          </Group>
                          <CloseButton
                            color="gray.5"
                            size="lg"
                            onClick={() =>
                              handleRemoveFromList(emailInvites, email, setEmailInvites)
                            }
                          />
                        </Group>
                      ))}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              )}
              {contests?.length > 0 && (
                <Accordion.Item value="splashContestsOnly">
                  <Accordion.Control>
                    <Text>
                      {t('invites.modal.splashContests__hasPlural', {
                        count: contests.length,
                      })}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="sm">
                      {contests
                        .sort((aJSON, bJSON) => {
                          const a: Contest = JSON.parse(aJSON);
                          const b: Contest = JSON.parse(bJSON);
                          return a.name > b.name ? 1 : -1;
                        })
                        .map((contestJSON) => {
                          const contest: Contest = JSON.parse(contestJSON);
                          return (
                            <Group key={contest.id} position="apart" noWrap>
                              <Group noWrap w={250}>
                                <Avatar
                                  radius="xl"
                                  variant="filled"
                                  size="md"
                                  src={getContestThumbnail({
                                    contestThumbnailUrl: contest.thumbnail_url,
                                    createdByAdmin: contest.created_by_admin,
                                    commissionerId: contest.commissioner.id,
                                  })}
                                />
                                <Text truncate>{contest.name}</Text>
                              </Group>
                              <CloseButton
                                color="gray.5"
                                size="lg"
                                onClick={() =>
                                  handleRemoveFromList(contests, contestJSON, setContests)
                                }
                              />
                            </Group>
                          );
                        })}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              )}
              {splashInvites.length > 0 && (
                <Accordion.Item value="splashOnly">
                  <Accordion.Control>
                    <Text>
                      {t('invites.modal.splashInvites__hasPlural', {
                        count: splashInvites.length,
                      })}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="sm">
                      {splashInvites
                        .sort((aJSON, bJSON) => {
                          const a: EntrantUser = JSON.parse(aJSON);
                          const b: EntrantUser = JSON.parse(bJSON);
                          return a.username > b.username ? 1 : -1;
                        })
                        .map((userJson) => {
                          const user = JSON.parse(userJson);
                          return (
                            <Group key={user.id} position="apart" noWrap>
                              <Group noWrap w={250}>
                                <Avatar radius="xl">
                                  <Text transform="uppercase" color="gray.9" fw={600}>
                                    {user.username?.slice(0, 2)}
                                  </Text>
                                </Avatar>
                                <Text truncate>{user.username}</Text>
                              </Group>
                              <CloseButton
                                color="gray.5"
                                size="lg"
                                onClick={() =>
                                  handleRemoveFromList(splashInvites, userJson, setSplashInvites)
                                }
                              />
                            </Group>
                          );
                        })}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              )}
              {rypPools.length > 0 && (
                <Accordion.Item value="rypPoolsOnly">
                  <Accordion.Control>
                    <Text>
                      {t('invites.modal.rypPools__hasPlural', {
                        count: rypPools.length,
                      })}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="sm">
                      {rypPools
                        .sort((aJSON, bJSON) => {
                          const a: Contest = JSON.parse(aJSON);
                          const b: Contest = JSON.parse(bJSON);
                          return a.name > b.name ? 1 : -1;
                        })
                        .map((poolJSON) => {
                          const pool: Contest = JSON.parse(poolJSON);
                          return (
                            <Group key={pool.id} position="apart" noWrap>
                              <Group noWrap w={250}>
                                <Avatar
                                  radius="xl"
                                  variant="filled"
                                  size="md"
                                  src={getContestThumbnail({
                                    contestThumbnailUrl: pool.thumbnail_url,
                                    createdByAdmin: pool.created_by_admin,
                                    commissionerId: pool.commissioner.id,
                                  })}
                                />
                                <Text truncate>{pool.name}</Text>
                              </Group>
                              <CloseButton
                                color="gray.5"
                                size="lg"
                                onClick={() =>
                                  handleRemoveFromList(rypPools, poolJSON, setRYPPools)
                                }
                              />
                            </Group>
                          );
                        })}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              )}
              {rypInvites.length > 0 && (
                <Accordion.Item value="rypOnly">
                  <Accordion.Control>
                    <Text>
                      {t('invites.modal.rypInvites__hasPlural', {
                        count: rypInvites.length,
                      })}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing="sm">
                      {rypInvites
                        .sort((aJSON, bJSON) => {
                          const a: EntrantUser = JSON.parse(aJSON);
                          const b: EntrantUser = JSON.parse(bJSON);
                          return a.username > b.username ? 1 : -1;
                        })
                        .map((userJson) => {
                          const user = JSON.parse(userJson);
                          return (
                            <Group key={user.id} position="apart" noWrap>
                              <Group noWrap w={250}>
                                <Avatar radius="xl">
                                  <Text transform="uppercase" color="gray.9" fw={600}>
                                    {user.username?.slice(0, 2)}
                                  </Text>
                                </Avatar>
                                <Text truncate>{user.username}</Text>
                              </Group>
                              <CloseButton
                                color="gray.5"
                                size="lg"
                                onClick={() =>
                                  handleRemoveFromList(rypInvites, userJson, setRYPInvites)
                                }
                              />
                            </Group>
                          );
                        })}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              )}
            </Accordion>
          )}
        </Stack>
      </ScrollArea>
    </Box>
  );
}

export { InviteModalRight };
