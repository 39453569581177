const PREFIX = 'contest-create';

export default {
  action: {
    cta: `${PREFIX}-action-cta`,
    next: `${PREFIX}-action-next`,
    back: `${PREFIX}-action-back`,
    submit: `${PREFIX}-action-submit`,
    login: `${PREFIX}-action-login`,
    invite: `${PREFIX}-action-invite`,
    view: `${PREFIX}-action-view`,
    editSettings: `${PREFIX}-action-edit-settings`,
    showAll: `${PREFIX}-action-view-all`,
    showLess: `${PREFIX}-action-view-less`,
  },
  container: `${PREFIX}-container`,
  field: (sectionKey: string, path: string[]) => `${PREFIX}-field-${sectionKey}-${path.join('-')}`,
  section: (sectionKey: string, name: 'label' | 'description') =>
    `${PREFIX}-section-${sectionKey}-${name}`,
  switchOption: `${PREFIX}-switch-option`,
  selectOption: `${PREFIX}-field-select-option`,
  checkOption: `${PREFIX}-field-check-option`,
  fieldMeta: {
    title: `${PREFIX}-field-meta-title`,
    description: `${PREFIX}-field-meta-description`,
    error: `${PREFIX}-field-meta-error`,
  },
  image: {
    container: `${PREFIX}-image-container`,
    success: `${PREFIX}-image-success`,
    reject: `${PREFIX}-image-error`,
    idle: `${PREFIX}-image-idle`,
    drag: `${PREFIX}-image-drag`,
  },
  numberField: `${PREFIX}-number-field`,
  summary: {
    label: (label: string) => `${PREFIX}-summary-label-${label}`,
    subLabel: (label: string) => `${PREFIX}-summary-sublabel-${label}`,
    field: (field: string) => `${PREFIX}-summary-field-${field}`,
    action: {
      change: `${PREFIX}-summary-action-change`,
    },
  },
  table: {
    container: `${PREFIX}-table-container`,
    head: {
      label: `${PREFIX}-table-header-label`,
    },
    body: {
      row: `${PREFIX}-table-body-row`,
      cell: `${PREFIX}-table-body-cell`,
      switch: `${PREFIX}-table-body-cell-switch`,
      select: `${PREFIX}-table-body-cell-select`,
    },
  },
  segments: {
    container: `${PREFIX}-segments-container`,
    action: {
      save: `${PREFIX}-segments-action-save`,
      edit: `${PREFIX}-segments-action-edit`,
    },
    edit: {
      slate: `${PREFIX}-segments-edit-slate`,
      segment: `${PREFIX}-segments-edit-segment`,
    },
    view: {
      segment: `${PREFIX}-segments-view-segment`,
    },
  },
  screen: {
    inprogress: `${PREFIX}-screen-inprogress`,
    submitted: `${PREFIX}-screen-submitted`,
  },
  drawer: {
    container: `${PREFIX}-drawer-container`,
    close: `${PREFIX}-drawer-close`,
  },
  previous: {
    container: `${PREFIX}-previous-container`,
    contest: `${PREFIX}-previous-contest`,
  },
};
