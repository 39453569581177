export default {
  passwordRequirement: 'components-shared-auth-passwordRequirement',
  passwordRequirementCheckIcon: 'components-shared-auth-passwordRequirementCheckIcon',
  passwordRequirementXIcon: 'components-shared-auth-passwordRequirementXIcon',
  passwordError: 'components-shared-auth-passwordError',
  passwordInput: 'components-shared-auth-passwordInput',
  signIn: {
    emailOrUsernameInput: 'components-shared-auth-signIn-emailOrUsernameInput',
    emailOrUsernameInputError: 'components-shared-auth-signIn-emailOrUsernameInputError',
    forgotPasswordLink: 'components-shared-auth-signIn-forgotPasswordLink',
    form: 'components-shared-auth-signIn-form',
    passwordInput: 'components-shared-auth-signIn-passwordInput',
    passwordInputError: 'components-shared-auth-signIn-passwordInputError',
    signUpLink: 'components-shared-auth-signIn-signUpLink',
    submitButton: 'components-shared-auth-signIn-submitButton',
  },
  signUp: {
    accordionTrigger: 'components-shared-auth-signUp-accordionTrigger',
    emailError: 'components-shared-auth-signUp-emailError',
    emailInput: 'components-shared-auth-signUp-emailInput',
    form: 'components-shared-auth-signUp-form',
    passwordError: 'components-shared-auth-signUp-passwordError',
    passwordInput: 'components-shared-auth-signUp-passwordInput',
    signInLink: 'components-shared-auth-signUp-signInLink',
    submitButton: 'components-shared-auth-signUp-submitButton',
    handleError: 'components-shared-auth-signUp-handleError',
    handleInput: 'components-shared-auth-signUp-handleInput',
    nameError: 'components-shared-auth-signUp-nameError',
    nameInput: 'components-shared-auth-signUp-nameInput',
  },
};
