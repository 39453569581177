export default {
  components: {
    contestCard: 'common-components-contestCard',
    appShellHeader: {
      username: 'common-components-appShellHeader-username',
      balance: 'common-components-appShellHeader-balance',
      mobileMenuButton: 'common-components-appShellHeader-mobileMenuButton',
      userMenuButton: 'common-components-appShellHeader-userMenuButton',
      menuItem: 'common-components-appShellHeader-menuItem',
      depositButton: 'common-components-appShellHeader-depositButton',
      headerUserInfo: {
        username: 'common-components-appShellHeader-headerUserInfo-username',
      },
    },
    appShellSubheader: {
      subHeaderLink: 'common-components-appShellSubheader-subHeaderLink',
    },
    modal: {
      confirmModal: {
        confirmButton: 'common-components-modal-confirmModal-confirmButton',
        cancelButton: 'common-components-modal-confirmModal-cancelButton',
      },
    },
    banner: {
      generic: 'common-components-banner-generic',
      verificationBanner: 'common-components-banner-verificationBanner',
    },
    appDownloadPrompt: {
      continueUsingWeb: 'common-components-appDownloadPrompt-continueUsingWeb',
    },
  },
};
