import { useTheme } from '@emotion/react';
import { Text, Box, Group, Stack, createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { Dispatch, SetStateAction } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Avatar } from '~/domains/common/components/Avatar/Avatar';
import { getContestThumbnail } from '~/domains/contest/domains/common/utils/getContestThumbnail';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';

const useStyles = createStyles((theme) => ({
  item: {
    padding: theme.spacing.sm,
    width: '100%',
    cursor: 'pointer',
  },
}));

type SingleContestProps = {
  contest: Pick<
    Contest,
    'name' | 'created_by_admin' | 'commissioner' | 'entries' | 'id' | 'thumbnail_url'
  >;
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  setSelectedMobileTab?: Dispatch<SetStateAction<string>>;
};

function SingleContest({
  contest,
  selectedItem,
  setSelectedItem,
  setSelectedMobileTab,
}: SingleContestProps) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  const isSelected = JSON.stringify(contest) === selectedItem;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });
  const handleSelect = () => {
    if (isSelected && isTablet) {
      setSelectedMobileTab('people');
    }
    setSelectedItem(JSON.stringify(contest));
  };

  return (
    <Box
      key={contest?.id}
      bg={isSelected ? theme.colors.splash[1] : 'transparent'}
      className={classes.item}
      onClick={handleSelect}
      sx={{ borderRadius: theme.radius.sm }}
    >
      <Group noWrap>
        <Avatar
          radius="xl"
          variant="filled"
          size="md"
          src={getContestThumbnail({
            contestThumbnailUrl: contest.thumbnail_url,
            createdByAdmin: contest.created_by_admin,
            commissionerId: contest.commissioner.id,
          })}
        />
        <Stack spacing={0}>
          <Text
            size={14}
            fw={700}
            lineClamp={1}
            color={
              isSelected
                ? theme.colors.dark[7]
                : theme.colors.gray[theme.colorScheme === 'dark' ? 3 : 5]
            }
          >
            {contest.name}
          </Text>
          <Text size={10} color="dimmed">
            {t('invites.modal.members__hasPlural', {
              count: contest.entries.filled ?? 0,
            })}
          </Text>
        </Stack>
      </Group>
    </Box>
  );
}

export default SingleContest;
