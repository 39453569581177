import { useTheme } from '@emotion/react';
import { Box, Container, SimpleGrid, Text, Title } from '@mantine/core';

import HeadTitle from '../HeadTitle';

import useStyles from './useStyles';

type ErrorScreenProps = {
  cta?: React.ReactNode[];
  description?: string | JSX.Element;
  errorCode?: string | number | JSX.Element;
  isCompact?: boolean;
  isComponentLevelError?: boolean;
  note?: string | JSX.Element;
  title: string;
};

export function ErrorScreen({
  cta,
  description,
  errorCode,
  isCompact,
  isComponentLevelError,
  note,
  title,
}: ErrorScreenProps) {
  const theme = useTheme();
  const { classes } = useStyles({ isCompact, isComponentLevelError, errorCode });

  return (
    <>
      {!isComponentLevelError && !isCompact && <HeadTitle title="Unknown error" />}
      <div className={classes.root}>
        <Container>
          <h1 className={classes.label}>{errorCode}</h1>
          <Title order={2} className={classes.title}>
            {title}
          </Title>
          <Text size="md" align="center" className={classes.description}>
            {description}
          </Text>
          <SimpleGrid
            spacing={8}
            cols={cta?.length}
            breakpoints={[
              { maxWidth: theme.breakpoints.sm, cols: cta?.length },
              { maxWidth: theme.breakpoints.xs, cols: 1 },
            ]}
          >
            {cta?.length > 0 && cta.map((button) => button)}
          </SimpleGrid>
          {note && (
            <Box mt="md">
              <Text color="gray.5">{note}</Text>
            </Box>
          )}
        </Container>
      </div>
    </>
  );
}
