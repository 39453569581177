export default {
  kyc: {
    form: {
      title: 'Verify to Play!',
      steps: {
        one: {
          title: 'Add your name and date of birth',
          subtitle:
            "Please make sure that your details match exactly what's on your photo ID or passport.",
          months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
          },
          fields: {
            firstName: {
              label: 'First Name',
              placeholder: 'First Name',
            },
            lastName: {
              label: 'Last Name',
              placeholder: 'Last Name',
            },
            dateOfBirth: {
              month: {
                label: 'Month',
                placeholder: 'MM',
              },
              day: {
                label: 'Day',
                placeholder: 'DD',
              },
              year: {
                label: 'Year',
                placeholder: 'YYYY',
              },
            },
          },
        },
        two: {
          title: 'Last step, we need your address & phone number!',
          subtitle: "Your details should match what's on your photo ID or passport.",
          fields: {
            address: {
              label: 'Address',
              placeholder: 'Address',
            },
            address2: {
              label: 'Address',
              placeholder: 'Address (optional)',
            },
            city: {
              label: 'City',
              placeholder: 'City',
            },
            state: {
              label: {
                US: 'State',
                CA: 'Province',
              },
              placeholder: {
                US: 'State',
                CA: 'Province',
              },
            },
            zipCode: {
              label: 'Zip Code',
              placeholder: 'Zip Code',
            },
            country: {
              label: 'Country',
              placeholder: 'Country',
            },
            mobileNumber: {
              label: 'Mobile Number',
              placeholder: 'Mobile Number',
            },
          },
        },
      },
      actions: {
        skip: 'Skip verification',
        back: 'Go Back',
        continue: 'Continue',
        submit: 'Submit',
      },
      validation: {
        invalid: 'Invalid {{name}}',
        required: '{{name}} is required',
        letters: '{{name}} must consist of letters only',
        numericInput: '{{name}} should only consist of numerical digits',
        stringLength: '{{name}} must be at least {{length}} characters long',
        exactStringLength: '{{name}} must be {{length}} characters long',
        numLength: '{{name}} must consist of {{length}} numbers',
        ageRestriction: 'Your age must be 18 or older',
        invalidDateOfBirth: 'Invalid date of birth',
        zip: {
          US: 'Invalid US zip code',
          CA: 'Invalid Canadian zip code',
        },
      },

      fetchingLocation: 'Fetching location...',
      submit: 'Submit Info',
      skip: 'Skip Verification',
    },
    introModal: {
      copy1:
        "You're almost there! Complete the final 2-steps to verify you are a human and can legally win the cash in your contest!",
      copy2:
        'For more information check the video above, or check out the <link>Privacy Policy</link> on verification.',
      cta: 'Get Started',
    },
  },
};
