import { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { isAxiosError } from 'axios';
import type { Translate } from 'next-translate';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { FEATURE } from '~/common/enums/feature.enum';
import { KYCContext } from '~/components/providers/KYCProvider';
import { LocationContext } from '~/components/providers/LocationProvider';
import { reportEvent } from '~/domains/analytics';
import { ExternalLink } from '~/domains/common/components/Link/Link';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { bugSnagLog } from '~/utils/bugsnag';
import { UserContext } from '~/components/providers/UserProvider';

import { createEntry } from '../../services/entries.service.api';

const CONTEST_QUERY_KEYS = ['contests', 'entriesForCurrentUser'];

const BEGINNER_CONTEST_ERROR = 'CONTEST_FOR_BEGINNERS_ONLY';

const isBeginnerContestError = (error: unknown): boolean =>
  isAxiosError(error) && error.response?.data?.error === BEGINNER_CONTEST_ERROR;

const parseResponseError = (error: unknown, t: Translate) => {
  if (isBeginnerContestError(error)) {
    return {
      title: t('enterContest.error.beginnerOnly.title'),
      message: (
        <Trans
          i18nKey="contest:enterContest.error.beginnerOnly.message"
          components={{
            legalLink: (
              <ExternalLink
                href="https://legal.splashsports.com/legal/splash-sports-house-rules"
                target="_blank"
              />
            ),
          }}
        />
      ),
    };
  }

  return {
    title: t('enterContest.error.default.title'),
    message: t('enterContest.error.default.message'),
  };
};

const useCreateOrJoinEntry = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('contest');
  const { user } = useContext(UserContext);

  const { refetch: getUserBalance } = useWalletBalance();
  const { locationStatus } = useContext(LocationContext);
  const { KYCStatus } = useContext(KYCContext);

  const isLocationCheckOnLoginEnabled = useIsFeatureEnabled(FEATURE.ENABLE_LOCATION_CHECK_ON_LOGIN);
  const disableDepositAndJoin = !useIsFeatureEnabled(
    FEATURE.ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS
  );
  const handleJoinThisContestClick = useCallback(
    async (
      contestId: string,
      entryFee: number,
      entriesCount: number,
      callback?: (entryIDs?: string[]) => unknown,
      suppressError?: boolean
    ) => {
      const notificationTitle = 'Could not join contest';

      // Location not allowed
      if (isLocationCheckOnLoginEnabled && locationStatus !== 'ALLOWED') {
        const notificationMessage =
          'Based on your current location, you are not currently allowed to enter this contest.';
        showNotification({
          title: notificationTitle,
          message: notificationMessage,
          color: 'red',
        });

        reportEvent('Contest > Join Not Allowed', {
          contest_id: contestId,
          entry_fee: entryFee,
          entry_fee_usd: entryFee / 100,
          entry_count: entriesCount,
          reason: 'Location Not Allowed',
        });

        if (!suppressError) {
          throw new Error('Location not allowed');
        }

        return;
      }

      // Invalid KYC status
      if (KYCStatus !== 'accept') {
        const notificationMessage = 'Please verify your information before entering a contest.';
        showNotification({
          title: notificationTitle,
          message: notificationMessage,
          color: 'red',
        });

        reportEvent('Contest > Join Not Allowed', {
          contest_id: contestId,
          entry_fee: entryFee,
          entry_fee_usd: entryFee / 100,
          entry_count: entriesCount,
          reason: 'Invalid KYC Status',
        });

        if (!suppressError) {
          throw new Error('Invalid KYC status');
        }

        return;
      }

      try {
        const entryIDs = await createEntry({
          contestId,
          entriesCount,
        });

        reportEvent('Contest > Join Now', {
          contest_id: contestId,
          entry_ids: entryIDs,
          entry_fee: entryFee,
          entry_count: entriesCount,
          entry_fee_usd: entryFee / 100,
        });

        await getUserBalance();

        callback?.(entryIDs);
      } catch (entryError) {
        const { title, message } = parseResponseError(entryError, t);

        showNotification({
          title,
          message,
          color: 'red',
        });

        reportEvent('Contest > Join Not Allowed', {
          contest_id: contestId,
          entry_fee: entryFee,
          entry_fee_usd: entryFee / 100,
          entry_count: entriesCount,
          reason: 'API Error',
          error: entryError,
        });

        bugSnagLog({
          context: 'Contest Join Error',
          severity: 'warning',
          errorMessage: title,
          errorDetails: { entryError },
          details: {
            title,
            message,
            contestId,
            caught: 'Yes',
          },
        });

        if (!suppressError) {
          throw entryError;
        }
      } finally {
        await Promise.all(
          CONTEST_QUERY_KEYS.map((queryKey) =>
            Promise.all([
              queryClient.invalidateQueries({ queryKey: [queryKey, contestId, user?.id] }),
              queryClient.invalidateQueries({ queryKey: [queryKey, contestId, user?.id] }),
            ])
          )
        );
      }
    },
    [
      KYCStatus,
      getUserBalance,
      isLocationCheckOnLoginEnabled,
      locationStatus,
      queryClient,
      t,
      user?.id,
    ]
  );

  const { mutateAsync, isLoading, isSuccess } = useMutation({
    mutationKey: ['createOrJoinEntry'],
    mutationFn: ({
      contestId,
      entryFee,
      entriesCount,
      callback,
      suppressError,
    }: {
      contestId: string;
      entryFee: number;
      entriesCount?: number;
      callback?: (entryIDs: string[]) => unknown;
      suppressError?: boolean;
    }) => handleJoinThisContestClick(contestId, entryFee, entriesCount, callback, suppressError),
  });

  return {
    handleCreateOrJoinContest: mutateAsync,
    isCreateorJoinButtonDisabled: disableDepositAndJoin,
    isLoading,
    isSuccess,
  };
};

export default useCreateOrJoinEntry;
