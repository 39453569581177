import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { QueryClientProvider } from '@tanstack/react-query';
import type { AppProps as NextAppProps } from 'next/app';

import FeatureFlagProvider from '~/components/providers/FeatureFlagProvider';
import { ThemeProvider } from '~/components/providers/ThemeProvider';
import UserProvider from '~/components/providers/UserProvider';
import AuthGuard from '~/components/providers/AuthGuard';
import ErrorBoundary from '~/components/shared/Errors/ErrorBoundary';
import ErrorScreen from '~/components/shared/Errors/ErrorScreen';
import queryClient from '~/config/initReactQuery';
import { initAnalytics, stopAnalytics, useReportLocationChange } from '~/domains/analytics';
import logVersionToConsole from '~/domains/common/utils/logVersionToConsole';
import type { NextPageComponent } from '~/domains/common/types/next-page-component.type';
import { MetaTags } from '~/domains/common/components/MetaTags/MetaTags';
import { RouteChangeOrMutationProgressBar } from '~/domains/app/components/RouteChangeOrMutationProgressBar/RouteChangeOrMutationProgressBar';
import { AppProviders } from '~/domains/common/app/AppProviders';
import { PageLayout } from '~/domains/common/app/PageLayout';

logVersionToConsole();

type AppProps = NextAppProps & { Component: NextPageComponent };

function App({ Component, pageProps = {} }: Partial<AppProps>) {
  const router = useRouter();

  const isGameshell = router.route.startsWith('/contest/[contestUuid]/game');
  const hasOwnAppshell = isGameshell || Component.hasOwnAppshell;
  const showErrorPage = router.route.startsWith('/error');
  const isPublicPage = Component.isPublic || false;
  const requiresAuth = !isPublicPage;
  const fullContest = pageProps.fullContest;
  const isDynamicContestLinkPreviewEnabled = pageProps.isDynamicContestLinkPreviewEnabled;
  const isDynamicContestLinkPreviewBase64Enabled =
    pageProps.isDynamicContestLinkPreviewBase64Enabled;
  const isDynamicContestLinkPreviewDeadlineEnabled =
    pageProps.isDynamicContestLinkPreviewDeadlineEnabled;
  const isInviteLink = Boolean(router.query.referral) || router.query.utm_source === 'Referral';

  useEffect(() => {
    initAnalytics();

    return () => {
      stopAnalytics();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useReportLocationChange();

  if (showErrorPage) {
    return (
      <>
        <MetaTags />
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <FeatureFlagProvider>
              <Component {...pageProps} />
            </FeatureFlagProvider>
          </UserProvider>
        </QueryClientProvider>
      </>
    );
  }

  return (
    <>
      <MetaTags
        fullContest={fullContest}
        isInviteLink={isInviteLink}
        isDynamicContestLinkPreviewEnabled={isDynamicContestLinkPreviewEnabled}
        isDynamicContestLinkPreviewBase64Enabled={isDynamicContestLinkPreviewBase64Enabled}
        isDynamicContestLinkPreviewDeadlineEnabled={isDynamicContestLinkPreviewDeadlineEnabled}
      />
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorScreen}>
          <AppProviders>
            {requiresAuth ? (
              <AuthGuard>
                <PageLayout hasOwnAppshell={hasOwnAppshell}>
                  <Component {...pageProps} />
                </PageLayout>
              </AuthGuard>
            ) : (
              <PageLayout hasOwnAppshell={hasOwnAppshell}>
                <Component {...pageProps} />
              </PageLayout>
            )}
            <RouteChangeOrMutationProgressBar />
          </AppProviders>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
}

export default App;
