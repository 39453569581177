export default {
  components: {
    paymentType: 'payments-withdrawal-components-paymentType',
  },
  paymentTypeForm: {
    continueButton: 'payments-withdrawal-paymentTypeForm-continueButton',
  },
  withdrawalForm: {
    cardSelect: 'payments-withdrawal-withdrawalForm-cardSelect',
    amountInput: 'payments-withdrawal-withdrawalForm-amountInput',
    summary: {
      serviceFee: 'payments-withdrawal-withdrawalForm-summary-serviceFee',
      transferAmount: 'payments-withdrawal-withdrawalForm-summary-transferAmount',
      remainingBalance: 'payments-withdrawal-withdrawalForm-summary-remainingBalance',
      withdrawalAmount: 'payments-withdrawal-withdrawalForm-summary-withdrawalAmount',
    },
  },
  withdrawButton: 'payments-withdrawal-withdrawButton',
  withdrawButtonTooltip: 'payments-withdrawal-withdrawButtonTooltip',
  withdrawnAmountText: 'payments-withdrawal-withdrawnAmountText',
  remainingBalanceText: 'payments-withdrawal-remainingBalanceText',
  continueButton: 'payments-withdrawal-continueButton',
};
