import { Tooltip as MantineTooltip } from '@mantine/core';
import type { ReactNode } from 'react';

type TooltipProps = {
  isRendered?: boolean;
  labels?: {
    label: string | ReactNode;
    condition?: boolean;
  }[];
} & Partial<React.ComponentProps<typeof MantineTooltip>>;

export function Tooltip({ isRendered = true, labels, events, ...rest }: TooltipProps) {
  const { children } = rest;
  if (!isRendered) return <div className={rest.className}>{children}</div>;

  const pickLabel = labels?.find(({ condition, label }) => {
    if (typeof condition === 'undefined') return label;
    return condition;
  })?.label;

  const defaultEvents = {
    hover: true,
    focus: true,
    touch: true,
  };

  return (
    <MantineTooltip events={events || defaultEvents} label={pickLabel} {...rest}>
      {children}
    </MantineTooltip>
  );
}
