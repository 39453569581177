import { getCommissionerAvatar } from './getCommissionerAvatar';

type GetContestThumbnailProps = {
  contestThumbnailUrl: string;
  createdByAdmin: boolean;
  commissionerId: string;
};

export const getContestThumbnail = ({
  contestThumbnailUrl,
  createdByAdmin,
  commissionerId,
}: GetContestThumbnailProps) => {
  if (contestThumbnailUrl) return contestThumbnailUrl;

  return getCommissionerAvatar({ commissionerId, createdByAdmin });
};
