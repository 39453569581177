export default {
  common: {
    fieldIsRequired: '{{field}} is required',
    fieldIsInvalid: '{{field}} is invalid',
    or: 'or',
    here: 'here',
  },
  signIn: {
    title: 'Log in',
    emailOrUsername: {
      label: 'Email',
      placeholder: 'name@email.com',
    },
    password: {
      label: 'Password',
      placeholder: 'Your Password',
    },
    dontHaveAnAccount: "Don't have an account?",
    signUpCTA: "Don't have an account? <signUpLink>Sign up</signUpLink>",
    oauthButton: 'Log In via',
  },
  signUp: {
    title: 'Sign up',
    createNewAccount: 'Create new account',
    handle: {
      label: 'Handle',
      placeholder: 'Handle',
      requirements: {
        notAllowedChars: 'Handle contains not allowed characters',
        startsEndsSpecialChars: 'Handle cannot start or end with special characters',
        hasConsecutiveSpecialChars: 'Handle cannot contain consecutive special characters',
        tooShort: 'Handle must not have less than 3 characters',
        tooLong: 'Handle must not have more than 20 characters',
        containsWhiteSpaces: 'Handle must not contain any whitespaces',
        isEmailAddress: 'Handle must not be an email address',
        atLeastOneLetter: 'Handle must contain at least one letter',
      },
    },
    name: {
      label: 'Name',
      placeholder: 'Your name',
    },
    email: {
      label: 'Email',
      placeholder: 'name@email.com',
      requirements: {
        restricted: 'We are unable to create an account with this email address.',
      },
    },
    password: {
      label: 'Password',
      placeholder: 'Your Password',
      requirements: {
        minLength: 'Includes at least 8 characters',
        number: 'Includes number',
        lowercase: 'Includes lowercase letter',
        uppercase: 'Includes uppercase letter',
        specialChar: 'Includes special symbol',
      },
    },
    cta: 'Play Now',
    goToAccount: 'Go to my account',
    alreadyLoggedIn: "You're already signed in",
    alreadyHaveAnAccount: 'Already have an account?',
    signInCTA: 'Already have an account? <signInLink>Sign in</signInLink>',
    legalBlurb:
      'Clicking "Play Now" confirms you are age 18+ (19+ in AL & 21+ MA, IA, LA, AZ) and agree to our <termsLink></termsLink> & <privacyLink></privacyLink>. You\'ll also get offers and messages from the SplashSports companies. Find out where Splash Sports is <whereCanIPlayLink></whereCanIPlayLink>.',
    gamblingProblemBlurb:
      'If you or someone you know has a gambling problem and wants help, call <phoneLink></phoneLink> or chat <chatLink></chatLink>',
    terms: 'Terms',
    privacyPolicy: 'Privacy Policy',
    whereCanIPlay: 'currently available',
  },
  eoValueProp: {
    title: 'Benefits are as easy as 1, 2, 3',
    linkYourExistingAccount: 'Sign up or link your existing RunYourPool Account',
    importedYourInfo: 'Transform the way you play.',
    verifyDetails: 'Make sure the following details are correct.',
    easilyImportInfo: 'Play against your friends for cash',
    inviteConnectionsToSplash: 'Earn money for creating the games everyone wants',
    getMoneyForYourFirstContest: 'We handle all entry fees and payouts for commissioners',
    blurb: {
      main: 'Sign up with RYP',
    },
  },
  resetPassword: {
    title: 'Reset Password',
    subtitle: 'Enter your email address and we will send you a link to reset your password.',
  },
  forgotPassword: {
    cta: 'Forgot Password?',
    title: 'Did you forget your password?',
    subtitle: 'Enter your email address and we will send you a link to reset your password.',
    linkSentTitle: 'Reset Password Link sent!',
    linkSentSubtitle:
      'If we have found an account linked to your handle/email, you will receive a link in your email inbox',
    fields: {
      emailOrUsername: 'Email',
    },
    errors: {
      minLength: 'This field cannot be empty',
    },
    errorTitle: 'Forgot Password Error',
    errorMessage: 'There was an error while requesting forgot password link',
    buttonLabel: 'Request password link',
    support:
      "If you have forgotten your email or can't access your account <link>contact support</link>.",
  },
  confirmPassword: {
    title: 'Choose new password',
    subtitle: 'Enter your new password and then click the button to change it.',
    yourNewPassword: 'Your new password',
    successTitle: 'Your password has been changed',
    successMessage: 'You can now log in with your new password',
    errorTitle: 'Confirm Password Error',
    errorMessage: 'There was an error while resetting your password.',
    buttonLabel: 'Reset Password',
  },
  errors: {
    password: {
      tooShort: 'Password should include at least 8 characters',
      needsUppercase: 'Password should have at least one uppercase letter',
      needsLowercase: 'Password should have at least one lowercase letter',
      needsNumber: 'Password should have at least one number',
      needsSpecialCharacter: 'Password should have at least one special character',
    },
  },
  signUpPromo: {
    subtitle: 'Never miss out on the action!',
    copy: 'Download the Splash Sports mobile app and turn on notifications to get pick reminders, the most up-to-date gameplay experience, social features, and additional payment methods!',
  },
  signUpActions: {
    title: 'Download and sign up on mobile',
    fastestOption: 'Fastest',
    downloadIos: 'Download for iOS',
    downloadAndroid: 'Download for Android',
    signUpVia: 'Sign up via',
    signUpOnWeb: 'Sign up on web',
  },
};
