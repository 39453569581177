export default {
  page: {
    locationInfo: {
      container: 'contestDetail-page-locationInfo-container',
      recheckLocationButton: 'contestDetail-page-locationInfo-recheckLocationButton',
    },
    decription: 'contestDetail-page-header-decription',
    header: {
      actions: {
        invite: 'contestDetail-page-header-actions-invite',
        copy: 'contestDetail-page-header-actions-copy',
        new: 'contestDetail-page-header-actions-new',
        view: 'contestDetail-page-header-actions-view',
        edit: 'contestDetail-page-header-actions-edit',
      },
      closeButton: 'contestDetail-page-header-closeButton',
      container: 'contestDetail-page-header-container',
      content: 'contestDetail-page-header-content',
      subtitle: 'contestDetail-page-header-subtitle',
      title: 'contestDetail-page-header-title',
      banner: 'contestDetail-page-header-banner',
      thumbnail: 'contestDetail-page-header-thumbnail',
    },
  },
  newEntryButton: 'contestDetail-newEntryButton',
  section: {
    about: {
      baseballIcon: 'contestDetail-section-about-baseballIcon',
      basketballIcon: 'contestDetail-section-about-basketballIcon',
      contestType: 'contestDetail-section-about-contestType',
      multiEntry: 'contestDetail-section-about-multiEntry',
      creator: 'contestDetail-section-about-creator',
      description: 'contestDetail-section-about-description',
      rosterRequirements: 'contestDetail-section-about-roster-requirements',
      footballIcon: 'contestDetail-section-about-footballIcon',
      game: {
        item: {
          collapse: 'contestDetail-section-about-game-item-collapse',
          container: 'contestDetail-section-about-game-item-container',
          date: 'contestDetail-section-about-game-item-date',
          name: 'contestDetail-section-about-game-item-name',
          nogames: 'contestDetail-section-about-game-item-nogames',
        },
        slate: {
          container: 'contestDetail-section-about-game-slate-container',
          title: 'contestDetail-section-about-game-slate-title',
        },
      },
      golfIcon: 'contestDetail-section-about-golfIcon',
      hockeyIcon: 'contestDetail-section-about-hockeyIcon',
      slate: 'contestDetail-section-about-slate',
      soccerIcon: 'contestDetail-section-about-soccerIcon',
      sport: 'contestDetail-section-about-sport',
      title: 'contestDetail-section-about-title',
      unknownIcon: 'contestDetail-section-about-unknownIcon',
      pickDeadline: 'contestDetail-section-about-pickDeadline',
      entryDeadline: 'contestDetail-section-about-entryDeadline',
    },
    myEntries: {
      item: {
        container: 'contestDetail-section-myEntries-item-container',
        image: 'contestDetail-section-myEntries-item-image',
        text: 'contestDetail-section-myEntries-item-text',
        teamAbbreviation: 'contestDetail-section-myEntries-item-teamAbbreviation',
      },
      modal: 'contestDetail-section-myEntries-modal',
      noEntries: 'contestDetail-section-myEntries-noEntries',
      row: {
        container: 'contestDetail-section-myEntries-row-container',
        options: {
          container: 'contestDetail-section-myEntries-row-options-container',
          edit: {
            text: 'contestDetail-section-myEntries-row-options-edit-text',
            link: 'contestDetail-section-myEntries-row-options-edit-link',
          },
          withdrawal: {
            text: 'contestDetail-section-myEntries-row-options-withdrawal-text',
          },
        },
        team: {
          button: 'contestDetail-section-myEntries-row-team-button',
          bestPickIcon: 'contestDetail-section-myEntries-row-team-bestPickIcon',
          abbreviationText: 'contestDetail-section-myEntries-row-team-abbreviationText',
        },
        title: 'contestDetail-section-myEntries-row-title',
        items: 'contestDetail-section-myEntries-row-items',
      },
      skeleton: 'contestDetail-section-myEntries-skeleton',
      title: 'contestDetail-section-myEntries-title',
      viewAll: 'contestDetail-section-myEntries-viewAll',
    },
    prizes: {
      row: 'contestDetail-section-prizes-row',
      title: 'contestDetail-section-prizes-title',
      resizableContest: 'contestDetail-section-prizes-resizableContest',
      standardContest: 'contestDetail-section-prizes-standardContest',
      guaranteedContest: 'contestDetail-section-prizes-guaranteedContest',
    },
    rules: {
      row: 'contestDetail-section-rules-row',
      title: 'contestDetail-section-rules-title',
    },
  },
};
