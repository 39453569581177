import { logError } from '~/common/utils/log';

const initSegment = (anonymousId: string) => {
  if (!process.env.NEXT_PUBLIC_SEGMENT_KEY) {
    return;
  }

  const analytics = global.analytics || [];
  global.analytics = analytics;

  if (analytics.initialize) {
    return;
  }

  if (analytics.invoked) {
    logError('Segment snippet included twice.');
    return;
  }

  analytics.invoked = true;
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];
  analytics.factory = (e) =>
    function (...args) {
      const t = Array.prototype.slice.call(args);
      t.unshift(e);
      analytics.push(t);
      return analytics;
    };

  analytics.methods.forEach((key) => {
    analytics[key] = analytics.factory(key);
  });
  analytics.setAnonymousId(anonymousId);

  analytics.load = function (key, e) {
    const t = document.createElement('script');
    t.type = 'text/javascript';
    t.async = !0;
    t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
    const n = document.getElementsByTagName('script')[0];
    n.parentNode.insertBefore(t, n);
    // eslint-disable-next-line no-underscore-dangle
    analytics._loadOptions = e;
  };
  // eslint-disable-next-line no-underscore-dangle
  analytics._writeKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;
  analytics.SNIPPET_VERSION = '4.15.3';
  analytics.load(process.env.NEXT_PUBLIC_SEGMENT_KEY);

  if (process.env.NEXT_PUBLIC_SEGMENT_DEBUG === 'true') {
    analytics.debug(true);
  }
};

const isSegmentInitialised = () => !!global.analytics;

const stopSegment = () => {
  const analytics = global.analytics || [];
  global.analytics = analytics;

  // TODO: Check documentation if this is correct
  if (analytics.instance === undefined) {
    return;
  }

  analytics.stop();
};

export { initSegment, isSegmentInitialised, stopSegment };
