import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { UserContext } from '~/components/providers/UserProvider';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { getCommissionerChannelDataByUser } from '~/services/commissioner/commissioner-service.service.api';

export function useCurrentUserCommissionerChannel() {
  const { user } = useContext(UserContext);
  const showCommissionerCTA = useIsFeatureEnabled(FEATURE.SHOW_COMMISSIONER_ONBOARDING_CTA);
  const { data, isLoading, fetchStatus } = useQuery({
    enabled: showCommissionerCTA && Boolean(user),
    queryKey: ['commissioner/channel'],
    queryFn: () => getCommissionerChannelDataByUser(),
  });

  return {
    data,
    // https://github.com/TanStack/query/issues/3584#issuecomment-1256986636
    isLoading: isLoading && fetchStatus !== 'idle',
    showCommissionerCTA,
  };
}
