import type {
  FullContest as APIFullContest,
  ContestType,
  UserContest,
} from '@betterpool/api-types/contests-service';

import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';
import parseTeamPicks from '~/domains/contest/domains/services/parsers/parseTeamPicks';
import { createQueryString } from '~/domains/common/utils/queryString';

import { contestsServiceInstance } from './contest.service.instance';
import type { FullContest } from './interfaces/full-contest.interface';
import type { Entrant } from './interfaces/entrant.interface';
import type { CreatePicksPayload, CreatePicksQuery } from './interfaces/create-picks.interface';
import type { APITeamPicks, TeamPicks } from './interfaces/team-picks.interface';
import parseFullContest from './parsers/parseFullContest';
import type { ContestStatistics } from './interfaces/contest-statistics';
import type { CreateTiersPayload } from './interfaces/create-tiers.interface';
import type { ContestStatusFilter } from './enums/contest-status-filter.enum';
import parseContestPicksheet from './parsers/parseContestPicksheet';
import type { APIPicksheet, Picksheet } from './interfaces/picksheet.interface';

// TODO: Infer correct FullContest by specific type
const getContest = async (contestId: string): Promise<FullContest> => {
  const response = await contestsServiceInstance.get<APIFullContest>(`/contests/${contestId}`);
  return parseFullContest(response.data);
};

const getContestEntries = async (
  contestId: string,
  limit = 100,
  offset = 0
): Promise<PaginatedData<Entrant>> => {
  const response = await contestsServiceInstance.get<PaginatedData<Entrant>>(
    `/contests/${contestId}/entries?limit=${limit}&offset=${offset}`
  );
  return response.data;
};

const getContestPicksheetBySlateId = async ({
  contestId,
  slateId,
  entryId,
  sort,
}: {
  contestId: string;
  slateId: string;
  entryId?: string;
  sort?: 'seed' | 'startTime';
}): Promise<Picksheet> => {
  const query = createQueryString({ contestId, entryId, sort });

  const response = await contestsServiceInstance.get<APIPicksheet>(
    `/slates/${slateId}/picksheets${query}`
  );

  return parseContestPicksheet(response.data);
};

const makeContestPicks = async (
  { contestId, entryId, slateId }: CreatePicksQuery,
  payload: CreatePicksPayload | CreateTiersPayload
): Promise<void> => {
  await contestsServiceInstance.post(
    `/contests/${contestId}/entries/${entryId}/slates/${slateId}/picks`,
    payload
  );
};

const getBulkContestTeamPicks = async (payload: {
  entryIds: string[];
  includeEntries?: boolean;
  slateId?: string;
}): Promise<TeamPicks> => {
  const response = await contestsServiceInstance.post<APITeamPicks>(`/team-picks`, payload);

  return parseTeamPicks(response.data);
};

const getContestStats = async ({
  contestId,
  slateId,
}: {
  contestId: string;
  slateId: string;
}): Promise<ContestStatistics> => {
  const response = await contestsServiceInstance.get<ContestStatistics>(
    `/contests/${contestId}/slates/${slateId}/statistics`
  );

  return response.data;
};

export type TSortByOption = 'SLATE_START' | 'ENTERED_DATE' | 'ENTRY_FEE' | 'PRIZE_POOL';
export type TSortByDir = 'ASC' | 'DESC';

const getContestSummaries = async (
  userId: string,
  payload: {
    limit: number;
    offset: number;
    sort?: {
      option: TSortByOption;
      dir: TSortByDir;
    };
    filters: {
      status?: ContestStatusFilter;
      contestType?: ContestType;
    };
  }
): Promise<PaginatedData<UserContest>> => {
  const response = await contestsServiceInstance.post<PaginatedData<UserContest>>(
    `/contests/users/${userId}/summaries`,
    payload
  );

  return response.data;
};

type LocationResponse = {
  country: string;
};

const getCurrentLocation = async (): Promise<LocationResponse> => {
  const response = await contestsServiceInstance.get<LocationResponse>('/locations/current');

  return response.data;
};

export {
  getContest,
  getContestPicksheetBySlateId,
  getContestEntries,
  getBulkContestTeamPicks,
  makeContestPicks,
  getContestStats,
  getContestSummaries,
  getCurrentLocation,
};
