export default {
  components: {
    deleteCardModal: {
      cancelButton: 'payments-deposits-components-deleteCardModal-cancelButton',
      container: 'payments-deposits-components-deleteCardModal-container',
      removeButton: 'payments-deposits-components-deleteCardModal-removeButton',
      text: 'payments-deposits-components-deleteCardModal-text',
    },
  },
  depositView: 'payments-deposits-depositsView',
  depositViewTitle: 'payments-deposits-depositViewTitle',
  depositForm: {
    paymentTypeButton: 'payments-deposits-depositForm-payment-paymentTypeButton',
    depositOption: 'payments-deposits-depositForm-payments-depositOption',
    cardSelect: {
      select: 'payments-deposits-depositForm-cardSelect-select',
      newCard: 'payments-deposits-depositForm-cardSelect-newCard',
      deleteCardIcon: 'payments-deposits-depositForm-cardSelect-deleteCardIcon',
      option: 'payments-deposits-depositForm-cardSelect-option',
    },
    cardDetailsFormSelection: {
      cardholderNameInput:
        'payments-deposits-depositForm-cardDetailsFormSelection-cardholderNameInput',
      cardNumberInput: 'payments-deposits-depositForm-cardDetailsFormSelection-cardNumberInput',
      container: 'payments-deposits-depositForm-cardDetailsFormSelection-container',
      expiryDateInput: 'payments-deposits-depositForm-cardDetailsFormSelection-expiryDateInput',
      cvvInput: 'payments-deposits-depositForm-cardDetailsFormSelection-cvvInput',
    },
    sameBillingAddressCheckbox: 'payments-deposits-depositForm-sameBillingAddressCheckbox',
    billingAddressAutocomplete: 'payments-deposits-billingAddressAutocomplete',
    billingAddressFormSection: 'payments-deposits-depositForm-billingAddressFormSection',
    billingCityInput: 'payments-deposits-depositForm-billingCityInput',
    billingStateSelect: 'payments-deposits-depositForm-billingStateSelect',
    billingZipInput: 'payments-deposits-depositForm-billingZipInput',
    billingCountrySelect: 'payments-deposits-depositForm-billingCountrySelect',
    youWillBeChargedBySplash: 'payments-deposits-depositForm-youWillBeChargedBySplash',
    submitButton: 'payments-deposits-depositForm-submitButton',
    customAmountInput: 'payments-deposits-depositForm-customAmountInput',
    cvvInput: 'payments-deposits-depositForm-cvvInput',
  },
  depositCompletedModal: {
    successMessage: 'payments-deposits-depositCompletedModal-successMessage',
    newBalance: 'payments-deposits-depositCompletedModal-newBalance',
    continueButton: 'payments-deposits-depositCompletedModal-continueButton',
    footerNote: 'payments-deposits-components-deleteCardModal-footerNote',
  },
};
