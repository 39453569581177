import type { FullContest } from '@betterpool/api-types';
import Head from 'next/head';
import queryString from 'query-string';

import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';
import { getContestPreviewImageData } from '~/domains/contest/utils/getContestPreviewImageData';

import { objectToBase64 } from '../../utils/base64';

type MetaTagsProps = {
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  isDynamicContestLinkPreviewEnabled?: boolean;
  isDynamicContestLinkPreviewBase64Enabled?: boolean;
  isDynamicContestLinkPreviewDeadlineEnabled?: boolean;
  isInviteLink?: boolean;
  fullContest?: FullContest;
};

const mainTitle = 'Splash Sports';

const getTitle = (title: string) => (title ? `${title} | ${mainTitle}` : mainTitle);

export function MetaTags({
  title,
  description = 'Splash Sports is the leading skill-based sports gaming platform built for friends to use their knowledge to compete for real money. Learn more and get started.',
  image = getCdnUrl('/images/logos/social-preview.png'),
  imageWidth = '1200',
  imageHeight = '628',
  isDynamicContestLinkPreviewEnabled,
  isDynamicContestLinkPreviewBase64Enabled,
  isDynamicContestLinkPreviewDeadlineEnabled,
  isInviteLink,
  fullContest,
}: MetaTagsProps) {
  const pageTitle = getTitle(fullContest ? fullContest.name : title);
  const pageDescription = fullContest?.short_rules || description;
  const isDeadlineEnabled = isDynamicContestLinkPreviewDeadlineEnabled;
  const partialContest = isDynamicContestLinkPreviewBase64Enabled
    ? objectToBase64(getContestPreviewImageData(fullContest))
    : undefined;

  const getPageImage = () => {
    if (isDynamicContestLinkPreviewEnabled) {
      const query = queryString.stringify({
        contestUuid: fullContest.id,
        isDeadlineEnabled,
        partialContest,
      });

      return `/og?${query}`;
    }
    if (isInviteLink) {
      return getCdnUrl('/images/logos/social-preview-invite.png');
    }

    return fullContest?.thumbnail_url || image;
  };

  const pageImage = getPageImage();

  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <meta property="og:title" content={pageTitle} key="og:title" />
      <meta name="twitter:title" content={pageTitle} key="twitter:title" />
      <meta name="description" content={pageDescription} key="description" />
      <meta property="og:description" content={pageDescription} key="og:description" />
      <meta name="twitter:description" content={pageDescription} key="twitter:description" />
      <meta property="og:image" content={pageImage} key="og:image" />
      <meta property="og:image:width" content={imageWidth} key="og:image:width" />
      <meta property="og:image:height" content={imageHeight} key="og:image:height" />
      <meta name="twitter:image" content={pageImage} key="twitter:image" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Head>
  );
}
