export default {
  avatar: {
    main: 'shared-header-avatar-main',
    drawer: {
      dropdownLink: 'shared-header-avatar-drawer-dropdownLink',
      username: 'shared-header-avatar-drawer-username',
    },
    hoverCard: {
      balance: 'shared-header-avatar-hoverCard-balance',
      username: 'shared-header-avatar-hoverCard-username',
    },
  },
  burgerMenuButton: 'shared-header-burgerMenuButton',
};
