export default {
  common: {
    header: {
      backLink: 'contest-gamecenter-common-header-backLink',
    },
    picks: {
      picksPanel: {
        picksMadeCount: 'contest-gamecenter-common-picksPanel-picksMadeCount',
        pick: 'contest-gamecenter-common-picksPanel-pick',
        container: 'contest-gamecenter-common-picksPanel-container',
        submit: 'contest-gamecenter-common-picksPanel-submit',
      },
      postPickModal: {
        container: 'contest-gamecenter-common-picks-postPickModal-container',
        title: 'contest-gamecenter-common-picks-postPickModal-title',
        subtitle: 'contest-gamecenter-common-picks-postPickModal-subtitle',
        pick: 'contest-gamecenter-common-picks-postPickModal-pick',
        pickName: 'contest-gamecenter-common-picks-postPickModal-pickName',
        actions: {
          newEntry: 'contest-gamecenter-common-picks-postPickModal-actions-newEntry',
          viewMyEntries: 'contest-gamecenter-common-picks-postPickModal-actions-viewMyEntries',
          continueEditing: 'contest-gamecenter-common-picks-postPickModal-actions-continueEditing',
        },
      },
    },
  },
  team: {
    picks: {
      sortOptionsSelect: 'contest-gamecenter-team-picks-sortOptionsSelect',
    },
    game: {
      container: {
        SCHEDULED: 'contest-gamecenter-team-picks-game-container-scheduled',
        IN_PROGRESS: 'contest-gamecenter-team-picks-game-container-inProgress',
        FINALIZED: 'contest-gamecenter-team-picks-game-container-finalized',
        FINISHED: 'contest-gamecenter-team-picks-game-container-finished',
      },
      team: 'contest-gamecenter-team-picks-game-team',
      teamName: 'contest-gamecenter-team-picks-game-teamName',
      radioButton: 'contest-gamecenter-team-picks-game-radioButton',
      seed: 'contest-gamecenter-team-picks-game-seed',
      previewButton: 'contest-gamecenter-team-picks-game-previewButton',
    },
    gameInfo: {
      container: 'contest-gamecenter-team-gameInfo-container',
    },
    entriesScheduled: {
      entry: {
        container: 'contest-gamecenter-team-entriesScheduled-entry-container',
        slate: 'contest-gamecenter-team-entriesScheduled-entry-slate',
        team: 'contest-gamecenter-team-entriesScheduled-entry-team',
        teamAbbr: 'contest-gamecenter-team-entriesScheduled-entry-teamAbbr',
        moreActionsButton: 'contest-gamecenter-team-entriesScheduled-entry-moreActionsButton',
        withdrawButton: 'contest-gamecenter-team-entriesScheduled-entry-withdrawButton',
      },
    },
    pickEm: {
      picksBar: {
        toggleExpand: 'contest-gamecenter-team-pickEm-picksBar-toggleExpand',
        fullPick: {
          container: 'contest-gamecenter-team-pickEm-picksBar-fullPick-container',
          teamName: 'contest-gamecenter-team-pickEm-picksBar-fullPick-teamName',
          shuffleButton: 'contest-gamecenter-team-pickEm-picksBar-fullPick-shuffleButton',
        },
      },
    },
  },
};
