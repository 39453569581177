import { createStyles } from '@mantine/core';

type UseStylesProps = {
  isMobile: boolean;
};
const useStyles = createStyles((theme, { isMobile }: UseStylesProps) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: isMobile ? theme.spacing.sm : theme.spacing.md,
    padding: theme.spacing.xs,
    textAlign: 'center',
  },
  iconWrapper: {
    ...(isMobile
      ? {
          width: 42,
          height: 42,
        }
      : {
          width: 64,
          height: 64,
        }),
    borderRadius: '100em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.white,
  },
}));

export default useStyles;
