export default {
  table: {
    filter: {
      sport: {
        badge: 'lobby-table-filter-sport-badge',
        container: 'lobby-table-filter-sport-container',
      },
    },
  },
};
