import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';

const BASE_COMMISSIONER_SVC_URL = `${process.env.NEXT_PUBLIC_API_URL_SPLASH}commissioner-service/api`;

const DEFAULT_SPLASH_THUMBNAIL = getCdnUrl('/images/commissioner/DefaultCommissionerAvatar.png');

export function getCommissionerAvatar({
  commissionerId,
  createdByAdmin,
}: {
  commissionerId: string;
  createdByAdmin: boolean;
}) {
  if (createdByAdmin) return DEFAULT_SPLASH_THUMBNAIL;

  return `${BASE_COMMISSIONER_SVC_URL}/channels/user/${commissionerId}/avatar`;
}
