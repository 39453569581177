export default {
  actions: {
    copyLink: 'Copy Link',
    returnToContestDetails: 'Return to Contest Details',
  },
  stats: {
    entries: 'Entries',
    entry: 'Entry',
    prizes: 'Prizes',
  },
  contestType: {
    multientry: 'Multi Entry',
    singleentry: 'Single Entry',
    topx: 'Top X',
    multiplier: 'Multiplier',
    team_survivor: 'Team Survivor',
    team_pick_em: "Team Pick'Em",
    undefined: 'Unknown',
  },
  experienceLevel: {
    experienced: 'Experienced Player',
    highlyExperienced: 'Highly Experienced Player',
  },
  enterContestModal: {
    enterContest: {
      title: 'Add entries',
      text: 'How many entries would you like to enter into this contest?',
      balance: 'Balance: {{balance}}',
      maxEntries__hasPlural: {
        one: '{{count}} max entry',
        other: '{{count}} max entries',
      },
      depositToEnter: 'Deposit to enter',
      addEntries__hasPlural: {
        one: 'Add {{count}} entry: {{entryFee}}',
        other: 'Add {{count}} entries: {{entryFee}}',
      },
      close: 'Close',
    },
    success: {
      title: 'Entry successful',
      text__hasPlural: {
        one: 'You have added {{count}} entry into this contest',
        other: 'You have added {{count}} entries into this contest',
      },
      balance: 'Your balance is now {{balance}}',
    },
  },
  enterContestError: {
    full: 'The contest is full',
    over: 'The contest has ended',
    cancel: 'The contest has been cancelled',
    inprogress: 'The contest has already started',
    default: 'You are not allowed to Create or Join entry',
    deadline: 'The contest deadline has passed',
    maxEntries: 'Max entries reached',
    locationLoading: 'Checking your location',
    locationInvalid: `Contest Type is not allowed in your location`,
    userInvalid: `Please verify your information before entering a contest`,
  },
  contestCard: {
    footer: {
      mySurvivors: 'My survivors',
      survivors: 'Survivors',
      myEntries: 'My entries',
      entries: 'Entries',
      entry: 'Entry',
      winning: 'Winning',
      won: 'Won',
      prizes: 'Prizes',
      segmented: 'Won (to date)',
    },
    banner: {
      makePicks: 'Make your pick(s)',
      draftLineup__hasPlural: {
        one: '{{count}} undrafted lineup',
        other: '{{count}} undrafted lineups',
      },
      lineupWithInjuries__hasPlural: {
        one: '{{count}} lineup with injuries',
        other: '{{count}} lineups with injuries',
      },
      picksheetNotAvailable: {
        teamBased: 'Pick sheet not yet available',
        playerBased: 'Player pool not yet available',
      },
    },
  },
};
