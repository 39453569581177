import Bugsnag from '@bugsnag/js';
import * as FullStory from '@fullstory/browser';
import { type CheckRequestStatus } from '@xpointtech/xpoint-js';

import log from '~/common/utils/log';
import type { User } from '~/services/users/types';
import { APIUserRole } from '~/services/users/types';

import { isBugSnagInitialised } from './initBugSnag';
import { isFullStoryInitialised } from './fullStory/initFullStory';
import { isSegmentInitialised } from './initSegment';
import { isGtagInitialised, reportGAevent, setGAuser } from './initGtag';
import { getGA4SessionData, removeAnalyticsSessionId } from './utils';

type AnalyticsUser = User & {
  geo_status?: CheckRequestStatus;
  geo_detected_state?: string;
  geo_detected_country?: string;
  walletBallance?: number;
};

const getAnalyticsMetaData = () => ({
  ...getGA4SessionData(),
});

const setAnalyticsUser = (user: Partial<User>) => {
  log('Analytics - Set analytics user', user);

  // Bugsnag identify
  if (isBugSnagInitialised()) {
    log('BugSnag - Set user');

    Bugsnag.setUser(`${user.id}`, user.email, user.username);
  }

  // Fullstory identify
  if (isFullStoryInitialised()) {
    log('FullStory - Set user');

    FullStory.setUserVars({
      displayName: user.username,
      email: user.email,
      id: user.id,
    });
  }

  // Segment identify
  if (isSegmentInitialised()) {
    log('Segment - Identify user');

    global.analytics.identify(user.id, {
      email: user.email,
      emailVerified: user.emailVerified,
      isCommissioner: user.role === APIUserRole.COMMISSIONER,
      username: user.username,
      ...getAnalyticsMetaData(),
    });
  }

  // Gtag identify
  if (isGtagInitialised()) {
    log('Analytics - Set GTM user data');
    setGAuser(user);
  }
};

const updateAnalyticsUser = (user: Partial<AnalyticsUser>) => {
  log('Segment - Update analytics user', user);

  if (isSegmentInitialised()) {
    global.analytics.identify(user.id, {
      country: user.country,
      createdAt: user.createdAt,
      geoCountry: user.geo_detected_country,
      geoState: user.geo_detected_state,
      geoStatus: user.geo_status,
      state: user.state,
      verificationStatus: user.verificationStatus,
      // We don't want to overwrite the hasFunds trait until we have the data
      ...(user.walletBallance
        ? {
            hasFunds: user.walletBallance > 0,
            walletBallance: user.walletBallance,
          }
        : {}),
      ...getAnalyticsMetaData(),
    });
  }
};

const clearAnalyticsUser = () => {
  log('Analytics - Clear session user');

  if (isBugSnagInitialised()) {
    Bugsnag.setUser(undefined);
  }

  if (isFullStoryInitialised()) {
    FullStory.setUserVars(undefined);
  }

  if (isSegmentInitialised()) {
    global.analytics.reset();
  }

  removeAnalyticsSessionId();
};

const commonProperties = {
  platform: 'web',
  version: process.env.VERSION,
};

const reportEvent = (name: string, properties: Record<string, unknown> = {}) => {
  const eventProperties = {
    ...properties,
    ...commonProperties,
    ...getAnalyticsMetaData(),
  };
  log('Analytics - Track event', name, eventProperties);

  if (isSegmentInitialised()) {
    global.analytics.track(name, eventProperties);
  }

  if (isFullStoryInitialised()) {
    FullStory.event(name, eventProperties);
  }

  if (isGtagInitialised()) {
    reportGAevent(name, eventProperties);
  }
};

const reportPageView = (path: string, title: string) => {
  log('Analytics - Page event', path, title);

  const pageProperties = {
    title,
    path,
    ...commonProperties,
    ...getAnalyticsMetaData(),
  };

  if (isSegmentInitialised()) {
    global.analytics.page(pageProperties);
  }

  if (isFullStoryInitialised()) {
    FullStory.setVars('page', pageProperties);
  }
};

export { clearAnalyticsUser, reportEvent, reportPageView, setAnalyticsUser, updateAnalyticsUser };
