import React from 'react';
import Cookies from 'js-cookie';

import { MaintenanceBanner } from '../MaintenanceBanner/MaintenanceBanner';
import ConnectedNewAppVersionBanner from '../NewAppVersionBanner/ConnectedNewAppVersionBanner';
import VerificationBanner from '../VerificationBanner/VerificationBanner';
import { useIsMobile } from '../../hooks/mediaQueries';

function AppBanners() {
  const isWebView = Cookies.get('webView') === 'true';
  const isMobile = useIsMobile();

  if (isWebView) {
    return false;
  }

  return (
    <>
      <VerificationBanner minimal={isMobile} />
      <MaintenanceBanner />
      <ConnectedNewAppVersionBanner />
    </>
  );
}

export default AppBanners;
