import { forwardRef } from 'react';
import type { MantineNumberSize } from '@mantine/core';
import {
  // eslint-disable-next-line no-restricted-imports
  Text as MantineText,
  createPolymorphicComponent,
  createStyles,
} from '@mantine/core';

import type { TextProps } from './Text';

export const titleStyles = {
  'headline-xxxl': {
    fontSize: 32,
    fontWeight: 900,
    lineHeight: 1.25,
  },
  'headline-xxl': {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.3333333333333333,
  },
  'headline-xl': {
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.5,
  },
  'headline-large': {
    fontSize: 16,
    fontWeight: 900,
    lineHeight: 1.5,
  },
  'headline-medium': {
    fontSize: 14,
    fontWeight: 900,
    lineHeight: 1.4285714285714286,
  },
  'headline-small': {
    fontSize: 12,
    fontWeight: 900,
    lineHeight: 1.5,
  },
  'headline-x-small': {
    fontSize: 10,
    fontWeight: 900,
    lineHeight: 1.2,
  },
  'headline-xx-small': {
    fontSize: 8,
    fontWeight: 900,
    lineHeight: 1.25,
  },
  'subhead-large': {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  'subhead-medium': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.5714285714285714,
  },
  'subhead-small': {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.6666666666666667,
  },
  'subhead-x-small': {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  'subhead-xx-small': {
    fontSize: 8,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  'title-large': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.375,
  },
  'title-medium': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4285714285714286,
  },
  'title-small': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.5,
  },
} as const;

export type TitleProps = {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?:
    | keyof typeof titleStyles
    | {
        base?: keyof typeof titleStyles;
        sm?: keyof typeof titleStyles;
        md?: keyof typeof titleStyles;
        lg?: keyof typeof titleStyles;
      };
} & Omit<TextProps, 'tag' | 'variant'>;

const useTitleStyles = createStyles((theme, { variant }: TitleProps) => ({
  title: {
    margin: 0,
    ...(() => {
      if (!variant) {
        return {};
      }

      if (typeof variant === 'string') {
        return titleStyles[variant];
      }

      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (key === 'base') {
          return { ...acc, ...titleStyles[value] };
        }

        return {
          ...acc,
          [theme.fn.largerThan(key as MantineNumberSize)]: {
            ...titleStyles[value],
          },
        };
      }, {});
    })(),
  },
}));

const TitleFactory = forwardRef<HTMLHeadingElement, TitleProps>(
  ({ tag, variant, className, ...props }, ref) => {
    const { cx, classes } = useTitleStyles({ variant });

    return (
      <MantineText component={tag} ref={ref} className={cx(classes.title, className)} {...props} />
    );
  }
);
TitleFactory.displayName = 'Title';

export const Title = createPolymorphicComponent<'h1', TitleProps>(TitleFactory);
