import { createStyles } from '@mantine/core';

import { titleStyles } from '~/domains/design-system/Title';

import { HEADER_HEIGHT } from '../constants';

const useCommonStyles = createStyles((theme) => ({
  link: {
    display: 'block',
    padding: '4px 8px',
    color: theme.colors.gray2[7],
    ...titleStyles['subhead-medium'],

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  linkIcon: {
    height: HEADER_HEIGHT,
    width: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },
  },
}));

export default useCommonStyles;
