export default {
  topSection: {
    withdrawalBalance: 'account-header-withdrawalBalance',
  },
  transactionHistory: {
    accordion: 'account-transactionHistory-accordion',
    noTransactionsLabel: 'account-transactionHistory-noTransactionsLabel',
    balanceCell: 'account-transactionHistory-balanceCell',
    transactionHistoryModalItem: 'account-transactionHistory-transactionHistoryModalItem',
    recentTransactions: 'account-transactionHistory-recentTransactions',
    pendingTransactions: 'account-transactionHistory-pendingTransactions',
    tableRow: 'acount-transactionHistory-tableRow',
    amountCell: 'acount-transactionHistory-amountCell',
  },
};
