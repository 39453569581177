import { useCallback, useEffect, useMemo, useState } from 'react';

interface ContestCountDownProps {
  contestStart: string | Date;
  color: string;
  fontSize: number;
  addPrefix: boolean;
  fontWeight?: number;
}

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ContestCountDown({
  contestStart = 'Jul 6, 2023 19:00:00',
  color = 'white',
  fontSize = 21,
  addPrefix = false,
  fontWeight = 400,
}: Partial<ContestCountDownProps>) {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(contestStart) - +new Date();
    const timeLeft: {
      d: string;
      h: string;
      m: string;
      s: string;
    } = {
      d: Math.floor(difference / (1000 * 60 * 60 * 24)).toString(),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24).toString(),
      m: Math.floor((difference / 1000 / 60) % 60).toString(),
      s: Math.floor((difference / 1000) % 60).toString(),
    };

    timeLeft.d = Number(timeLeft.d) < 10 ? `0${timeLeft.d}` : timeLeft.d;
    timeLeft.h = Number(timeLeft.h) < 10 ? `0${timeLeft.h}` : timeLeft.h;
    timeLeft.m = Number(timeLeft.m) < 10 ? `0${timeLeft.m}` : timeLeft.m;
    timeLeft.s = Number(timeLeft.s) < 10 ? `0${timeLeft.s}` : timeLeft.s;

    return timeLeft;
  }, [contestStart]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  }, [calculateTimeLeft]);

  const content = useMemo(() => {
    if (Number(timeLeft.d) < 7 && Number(timeLeft.d) >= 1) {
      return <DaysAndHours start_date={new Date(contestStart)} addPrefix={addPrefix} />;
    }

    if (Number(timeLeft.d) < 1) {
      return (
        <HoursAndMinutes
          hour={timeLeft.h}
          minute={timeLeft.m}
          second={timeLeft.s}
          addPrefix={addPrefix}
        />
      );
    }

    // More than 7 days in the future or already in the past
    return <Weeks start_date={new Date(contestStart)} addPrefix={addPrefix} />;
  }, [addPrefix, contestStart, timeLeft.d, timeLeft.h, timeLeft.m, timeLeft.s]);

  return (
    <span
      data-chromatic="ignore"
      style={{
        color,
        fontSize,
        fontWeight,
      }}
    >
      {content}
    </span>
  );
}

interface WeeksProps {
  start_date: Date;
  addPrefix: boolean;
}

function Weeks({ start_date, addPrefix }: WeeksProps) {
  return (
    <span style={{ color: '', whiteSpace: 'nowrap' }}>
      {addPrefix && <>ON </>}
      {start_date.toLocaleDateString('en-US', {
        dateStyle: 'short',
      })}
    </span>
  );
}

interface DaysAndHoursProps {
  start_date: Date;
  addPrefix?: boolean;
}

function DaysAndHours({ addPrefix, start_date }: DaysAndHoursProps) {
  return (
    <span>
      {addPrefix && <>ON </>}
      {start_date.toLocaleDateString('en-US', {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      })}
    </span>
  );
}

interface HoursAndMinutesProps {
  hour: string;
  minute: string;
  second: string;
  addPrefix: boolean;
}

function HoursAndMinutes({ hour, minute, second, addPrefix }: HoursAndMinutesProps) {
  return (
    <>
      {addPrefix && <span>IN </span>}
      <span>{hour}:</span>
      <span>{minute}:</span>
      <span>{second}</span>
    </>
  );
}
