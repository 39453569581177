'use client';

import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

import { isBrowserFn } from '../utils';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum FS_VISIBILITY {
  FS_HIDE = 'fs-mask',
  FS_SHOW = 'fs-unmask',
  FS_EXCLUDE = 'fs-exclude',
}

function isFSenum(value: string): value is FS_VISIBILITY {
  return Object.values<string>(FS_VISIBILITY).includes(value);
}

/**
 * DESCRIPTION:
 * This hook is used to set the component's visibility in FullStory recording.
 * It desn't override mantine & styled generated classes
 *
 * USAGE:
 * 1. Import the hook and the enum
 * 2. Call the hook before the render with desired visibility level
 *    (you can create multiple refs with different levels):
 *    `const FS_HIDE = useFullStoryRef(FS_VISIBILITY.FS_HIDE);`
 * 3. Add the ref to the component:
 *    `<div ref={FS_HIDE}>...</div>`
 *
 * NOTES:
 * 1. Component can have only one visibility level
 * 2. Only use FS_HIDE and FS_EXCLUDE for hardcoded classnames (except for <body>)!
 * 3. Only use hardcoded classNames on regular DOM components without any styling,
 *    otherwise it might overwrite the generated classes (in styled components for example).
 * 4. Visibility levels are hereditary (if a parent is hidden, all children are hidden),
 *    but can be rewritten by nested levels
 * 5. FS_EXCLUDE will exclude all children components and cannot be overriden
 * 6. Default visibility level for all application components is "FS_SHOW" (set as a class on <body>)
 * 7. Default visibility level for the hook is "FS_HIDE"
 * @param visibility
 * @returns refObject
 */
const useFullStoryRef = <R extends HTMLDivElement>(
  visibility = FS_VISIBILITY.FS_HIDE
): MutableRefObject<R> => {
  const fsRef = useRef(null);

  useEffect(() => {
    if (!isBrowserFn()) return;
    if (!isFSenum(visibility)) return;

    const refComponent = fsRef.current;
    if (refComponent) {
      refComponent.className += (refComponent.className !== '' ? ' ' : '') + visibility;
      refComponent.setAttribute('data-fs-privacy', visibility);
    }
  }, []);

  return fsRef;
};

export { FS_VISIBILITY, useFullStoryRef };
