import type { ContestType } from '@betterpool/api-types/contests-service';

import type { ContestStatusFilter } from '../domains/services/enums/contest-status-filter.enum';
import type { LeaderboardType } from '../domains/services/leaderboard.service.api';

const STALE_TIME = 30_000;
const INFINITE_SCROLL_PAGE_SIZE = 20;
export const CONTEST_QUERY_KEY_PREFIX = 'contest';

const ContestQueryKeys = {
  // TODO: Replace 'contests' with CONTEST_QUERY_KEY_PREFIX when the old gameshell will be deleted
  CONTEST_DETAIL: ({ contestId, userId }) => ['contests', contestId, userId],
  LEADERBOARD: ({
    contestId,
    slateId,
    userId,
  }: {
    contestId: string;
    slateId: string;
    userId?: string;
  }) => [CONTEST_QUERY_KEY_PREFIX, contestId, slateId, 'leaderboard', userId],
  LEADERBOARD_INFINITE: ({
    contestId,
    slateId,
    userId,
    leaderboardType,
    segmentId,
    includeRecord,
    picksSlateId,
  }: {
    contestId: string;
    slateId?: string;
    userId?: string;
    leaderboardType?: string;
    segmentId?: string;
    includeRecord?: boolean;
    picksSlateId?: string;
  }) =>
    [
      CONTEST_QUERY_KEY_PREFIX,
      contestId,
      'leaderboardInfinite',
      userId,
      slateId,
      leaderboardType,
      segmentId,
      includeRecord,
      picksSlateId,
    ].filter(Boolean),
  LEADERBOARD_WITH_PICKS_INFINITE: ({
    contestId,
    slateId,
    userId,
  }: {
    contestId: string;
    slateId?: string;
    userId?: string;
  }) =>
    [CONTEST_QUERY_KEY_PREFIX, contestId, 'leaderboardWithPicksInfinite', userId, slateId].filter(
      Boolean
    ),
  LEADERBOARD_SUMMARY: ({
    contestId,
    slateId,
    segmentId,
    leaderboardType,
  }: {
    contestId: string;
    slateId: string;
    segmentId?: string;
    leaderboardType?: LeaderboardType;
  }) => [
    CONTEST_QUERY_KEY_PREFIX,
    contestId,
    slateId,
    'leaderboardSummary',
    segmentId,
    leaderboardType,
  ],
  USER_CONTESTS: ({
    userId,
    status,
    contestType,
    limit,
    sort,
  }: {
    userId: string;
    status: ContestStatusFilter;
    contestType: ContestType;
    limit: number;
    sort?: { option: string; dir: string };
  }) => [CONTEST_QUERY_KEY_PREFIX, 'userContests', userId, status, contestType, limit, sort],
  PICKSHEET: ({
    contestId,
    slateId,
    entryId,
    sort,
  }: {
    contestId: string;
    slateId: string;
    entryId?: string;
    sort?: 'seed' | 'startTime';
  }) => [
    CONTEST_QUERY_KEY_PREFIX,
    contestId,
    slateId,
    entryId,
    'picks',
    'picksheet',
    ...(sort ? [sort] : []),
  ],
  ENTRIES_CURRENT_USER_WITH_PICKS: ({ contestId, userId }) => [
    CONTEST_QUERY_KEY_PREFIX,
    contestId,
    userId,
    'entriesWithPicks',
  ],
  ENTRY_BREAKDOWN_INFINITE: ({
    contestId,
    entryId,
    leaderboardType,
    segmentId,
    includeRecord,
  }: {
    contestId: string;
    entryId: string;
    leaderboardType?: 'CUMULATIVE' | 'SLATE' | 'SEGMENT';
    segmentId?: string;
    includeRecord?: boolean;
  }) => [
    CONTEST_QUERY_KEY_PREFIX,
    contestId,
    entryId,
    'entryBreakdownInfinite',
    leaderboardType,
    segmentId,
    includeRecord,
  ],
};

const ContestMutationKeys = {
  PICKS_SUBMIT: ({ contestId, slateId }: { contestId: string; slateId: string }) => [
    'contest',
    contestId,
    slateId,
    'picksSubmit',
  ],
  UPDATE_ENTRY: (entryId?: string) => ['contest', 'entry', entryId],
};

export { ContestQueryKeys, ContestMutationKeys, INFINITE_SCROLL_PAGE_SIZE, STALE_TIME };
