import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import type { APIAuthUserData } from '~/services/users/types';

const ANONYMOUS_ID_KEY = 'ajs_anonymous_id';
const ANONYMOUS_ID_PREFIX = 'anonymous-user-';
const ANALYTICS_SESSION_ID = 'analytics-session-id-fallback';

const isBrowserFn = () => typeof window !== 'undefined' && Boolean(window.navigator);

const setAnonymousId = (id = `${ANONYMOUS_ID_PREFIX}${uuid()}`) => {
  Cookies.set(ANONYMOUS_ID_KEY, id, {
    expires: 365,
    domain: 'splashsports.com',
  });
  return id;
};

const getAnonymousId = () => {
  // Get the anonymous ID from the URL if it's set
  const urlParams = new URLSearchParams(window?.location?.search);
  const urlAnonymousID = urlParams.get('ref_anon_id');

  return urlAnonymousID || Cookies.get(ANONYMOUS_ID_KEY) || setAnonymousId();
};

const getStoredUser = (): APIAuthUserData => {
  if (!isBrowserFn()) {
    return null;
  }

  try {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    return userData;
  } catch (e) {
    return null;
  }
};

type GA4SessionData = {
  ga_session_id: string;
  ga_session_number: string;
};
function getGA4SessionData(): GA4SessionData | undefined {
  if (!isBrowserFn()) return;

  // GA4 stream ID that stores the cookie for splashsports.com
  const GAstreamId = process.env.NEXT_PUBLIC_GA4_STREAMID;
  const cookie = Cookies.get(`_ga_${GAstreamId}`);

  // If GA session ID is not stored, get or generate the fallback
  if (!cookie) {
    let sessionID = Cookies.get(ANALYTICS_SESSION_ID);
    if (!sessionID) {
      sessionID = Date.now().toString().slice(0, -3);
      Cookies.set(ANALYTICS_SESSION_ID, sessionID);
    }

    return {
      ga_session_id: sessionID,
      ga_session_number: '1',
    };
  }

  const parsedCookie = cookie.split('.').slice(2, -1);
  return {
    ga_session_id: parsedCookie.shift(),
    ga_session_number: parsedCookie.shift(),
  };
}

const removeAnalyticsSessionId = () => {
  Cookies.remove(ANALYTICS_SESSION_ID);
};

export { getAnonymousId, getGA4SessionData, getStoredUser, isBrowserFn, removeAnalyticsSessionId };
