import { Box, Group, Menu, Stack } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';

import { Routes } from '~/domains/common/constants/routes';
import { isBrowserFn } from '~/domains/analytics';

import { ButtonLink, PrimaryButtonLink } from '../Button/Button';

import { HeaderNavigation } from './HeaderNavigation';
import { HeaderUserInfo } from './HeaderUserInfo';

export function HeaderLoggedInMobile() {
  const { t } = useTranslation('common');

  const { withdrawLink, depositLink } = useMemo(
    () => ({
      withdrawLink: Routes.withdraw(
        isBrowserFn()
          ? { redirectTo: `${window.location.pathname}${window.location.search}` }
          : undefined
      ),
      depositLink: Routes.deposit(
        isBrowserFn()
          ? { redirectTo: `${window.location.pathname}${window.location.search}` }
          : undefined
      ),
    }),
    []
  );

  return (
    <Stack w="100%">
      <Box
        p="md"
        sx={(theme) => ({
          background: theme.black,
          color: theme.white,
          flexGrow: 1,
        })}
      >
        <HeaderUserInfo />
        <Group spacing="xs" grow>
          <ButtonLink variant="outline" href={withdrawLink}>
            {t('headerNavigation.withdraw')}
          </ButtonLink>
          <PrimaryButtonLink href={depositLink}>{t('headerNavigation.deposit')}</PrimaryButtonLink>
        </Group>
      </Box>
      {/*
        We need Menu, because the HeaderNavigation items
        are using Menu.Item, which are needed for desktop
        */}
      <Menu>
        <HeaderNavigation />
      </Menu>
    </Stack>
  );
}
