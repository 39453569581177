import useTranslation from 'next-translate/useTranslation';
import type { BadgeVariant, MantineSize, MantineColor } from '@mantine/core';
import { Badge, useMantineTheme, createStyles } from '@mantine/core';
import { ContestType } from '@betterpool/api-types/contests-service';

type ContestTypeBadgeProps = {
  type: ContestType;
  colorScheme?: 'light' | 'dark';
};

const useStyles = createStyles({
  label: {
    fontSize: 10,
    textTransform: 'capitalize',
  },
});

function ContestTypeBadge({ type, colorScheme = 'light' }: ContestTypeBadgeProps) {
  const { t } = useTranslation('common');
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const props = {
    variant: 'filled' as BadgeVariant,
    c: (colorScheme === 'light' ? theme.white : theme.colors.gray[9]) as MantineColor,
    bg: (colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[3]) as MantineColor,
    size: 'sm ' as MantineSize,
    radius: 'lg' as MantineSize,
    px: 6,
    className: classes.label,
  };

  switch (type) {
    case ContestType.TEAM_PICK_EM:
      return (
        <Badge bg {...props}>
          {t('contest.pickEm')}
        </Badge>
      );
    case ContestType.TEAM_SURVIVOR:
      return <Badge {...props}>{t('contest.survivor')}</Badge>;
    case ContestType.PLAYER_TIER:
      return <Badge {...props}>{t('contest.tiers')}</Badge>;
    case ContestType.PLAYER_ONE_AND_DONE:
      return <Badge {...props}>{t('contest.oneAndDone')}</Badge>;
    default:
      return null;
  }
}

export default ContestTypeBadge;
