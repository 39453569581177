import type { Contest } from '@betterpool/api-types';

// eslint-disable-next-line no-restricted-syntax
export enum NotificationType {
  // users
  USER_SIGN_UP = 'user-sign-up',
  USER_KYC_STATUS_UPDATED = 'user-kyc-status-updated',
  // contests
  NEW_ENTRY = 'new-entry',
  CONTEST_INVITE = 'contest-invite',
  CONTEST_JOINED = 'contest-joined',
  CONTEST_CANCELLED = 'contest-cancelled',
  CONTEST_WON = 'contest-won',
  CONTEST_EDITED = 'contest-edited',
  // transactions
  DEPOSIT_CONFIRMED = 'deposit-confirmed',
  WITHDRAWAL_CONFIRMED = 'withdrawal-confirmed',
  BONUS_CONFIRMED = 'bonus-confirmed',
  CHANNEL_FOLLOWED = 'channel-followed',
}

type NotificationContest = Pick<Contest, 'id' | 'name' | 'tags' | 'league'> & {
  thumbnailUrl: Contest['thumbnail_url'];
  contestType: Contest['contest_type'];
};

type User = {
  id: string;
};

type ContestCreatedPayload = {
  user: User;
  contest: NotificationContest;
};

type Sender = {
  userId: string;
};

type Recipient = {
  userId: string;
};

type BaseNotification = {
  id: string;
  type: NotificationType;
  senderChannel?: Channel;
  readAt: string | null;
  createdAt: string;
};

type ContestInvitePayload = {
  user: User;
  contest: NotificationContest;
  sender: Sender;
  recipient: Recipient;
};

type ContestUpdatedPayload = {
  user: User;
  contest: NotificationContest;
  previousContest: Partial<Contest>;
};

export type Channel = {
  id: string;
  userId: string;
  name: string;
  handle: string;
  avatarUrl: string;
};

export type Notification =
  | (BaseNotification & {
      type: NotificationType.CHANNEL_FOLLOWED;
      payload: ContestCreatedPayload;
    })
  | (BaseNotification & {
      type: NotificationType.CONTEST_INVITE;
      payload: ContestInvitePayload;
    })
  | (BaseNotification & {
      type: NotificationType.CONTEST_EDITED;
      payload: ContestUpdatedPayload;
    });

export type GetNotificationsResponse = {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  id: string;
  data: Notification[];
};

export type GetNotificationsCountResponse = {
  id: string;
  data: {
    count: number;
  };
};

export type ReadNotificationsResponse = {
  id: string;
  data: {
    notificationIds: string[];
  };
};
