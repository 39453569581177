import { NotificationType } from '../notifications.types';

export default {
  notifications: {
    today: 'Today',
    content: {
      [NotificationType.CHANNEL_FOLLOWED]: '<handle></handle> followed you!',
      [NotificationType.CONTEST_INVITE]: '<handle></handle> invited you to a <contest></contest>!',
      [NotificationType.CONTEST_EDITED]:
        '<handle></handle> updated a <contestName></contestName> contest!',
    },
  },
  noResults: {
    title: 'No notifications',
    text: 'New followers and contest invites appear here.',
  },
  readAll: 'Mark all as read',
};
