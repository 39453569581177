import type { ContestType, Sport } from '@betterpool/api-types/contests-service';

import { Routes } from '~/domains/common/constants/routes';
import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';

import { useIsGamecenterEnabled } from './useIsGamecenterEnabled';

const useGamecenterPath = ({
  id,
  contestType,
  sport,
  isAfterEntryDeadline,
  userEntriesCount,
  promoSource,
  promoIndex,
}: {
  id: string;
  contestType: ContestType;
  sport: Sport;
  isAfterEntryDeadline: boolean;
  userEntriesCount: number;
  promoSource?: string;
  promoIndex?: number;
}) => {
  const { isReady, isEnabled } = useIsGamecenterEnabled(contestType, sport);

  return isReady && isEnabled
    ? Routes.gamecenter({
        contestId: id,
        isAfterEntryDeadline,
        userEntriesCount,
      })
    : Routes.contestDetail(id, {
        [URLQueryKeys.UTM_SOURCE]: promoSource,
        [URLQueryKeys.UTM_CAMPAIGN]: `${promoIndex}`,
      });
};

export default useGamecenterPath;
