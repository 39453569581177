import { Burger, Divider, Group, Stack, createStyles } from '@mantine/core';
import Cookies from 'js-cookie';
import useTranslation from 'next-translate/useTranslation';
import React, { useContext, useMemo } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { UnstyledNextLink } from '~/components/shared/UnstyledNextLink/UnstyledNextLink';
import { CurrencyConvertor } from '~/components/utils/formatters';
import { isBrowserFn } from '~/domains/analytics';
import { Routes } from '~/domains/common/constants/routes';
import { ContestStatusFilter } from '~/domains/contest/domains/services/enums/contest-status-filter.enum';
import useContestsUser from '~/domains/contest/hooks/useContestsUser';
import { Avatar } from '~/domains/design-system/Avatar';
import { ButtonLink } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import type { APIAuthUserData } from '~/services/users/types';

import tc from '../../testing.const';
import HeaderDrawer from '../common/HeaderDrawer';
import { HeaderMenusContext } from '../HeaderMenusContext';
import NotificationBadge from '../NotificationBadge';

import useCommonStyles from './useCommonStyles';

const useStyles = createStyles((theme) => ({
  burgerButton: {
    marginLeft: 'auto',
  },
  userContainer: {
    background: theme.black,
    color: theme.white,
    padding: theme.spacing.lg,
  },
  balance: {
    color: theme.colors.green[4],
  },
  depositAndWithdraw: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    gap: theme.spacing.md,
    marginTop: theme.spacing.md,
  },
  navLinkBadge: {
    display: 'inline-block',
    marginLeft: theme.other.spacing._4,
  },
}));

type MobileMenuProps = {
  user?: APIAuthUserData;
  avatarUrl?: string;
  channelHandle?: string;
  channelSlug?: string;
  balance?: number;
  isUserVerified?: boolean;
};

function MobileMenu({
  user,
  avatarUrl,
  channelHandle,
  channelSlug,
  balance,
  isUserVerified,
}: MobileMenuProps) {
  const { classes, theme } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { t } = useTranslation('common');
  const { menuId, onMenuOpen, onMenuToggle } = useContext(HeaderMenusContext);
  const isOpen = menuId === 'user';
  const { itemsTotal: entriesActionRequiredTotal } = useContestsUser({
    status: ContestStatusFilter.ACTION_REQUIRED,
    limit: 0,
  });

  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);
  const isPropsEnabled = useIsFeatureEnabled(FEATURE.ENABLE_PROPS);
  const isEntriesPageEnabled = useIsFeatureEnabled(FEATURE.ENABLE_ENTRIES_PAGE);

  const navLinks = useMemo(
    () => [
      ...(!isUserVerified
        ? [[{ href: Routes.userVerification(), label: t('appShellHeader.verifyUser') }]]
        : []),
      [
        {
          label: t('appShellHeader.createAContest'),
          href: Routes.createAContest(),
        },
      ],
      [
        {
          label: t('appShellHeader.home'),
          href: Routes.home(),
        },
        {
          label: t('appShellHeader.contestLobby'),
          href: Routes.contestLobby(),
        },
        ...(isPropsEnabled
          ? [
              {
                href: Routes.quickPicks(),
                label: (
                  <>
                    {t('appShellHeader.quickPicks')}
                    {!Cookies.get('quickPicksTutorialSeen') && (
                      <div className={classes.navLinkBadge}>
                        <NotificationBadge showEmpty />
                      </div>
                    )}
                  </>
                ),
              },
            ]
          : []),
        ...(isEntriesPageEnabled
          ? [
              {
                href: Routes.entries(),
                label: (
                  <>
                    {t('appShellHeader.entries')}
                    {entriesActionRequiredTotal ? (
                      <div className={classes.navLinkBadge}>
                        <NotificationBadge backgroundColor={theme.white}>
                          {entriesActionRequiredTotal}
                        </NotificationBadge>
                      </div>
                    ) : null}
                  </>
                ),
              },
            ]
          : [{ href: Routes.myContests(), label: t('appShellHeader.myContests') }]),
        // eslint-disable-next-line no-nested-ternary
        ...(isChannelsV1Enabled && channelHandle
          ? [{ href: Routes.channel(channelHandle), label: t('appShellHeader.myChannel') }]
          : channelSlug
            ? [{ href: Routes.commissionerHub(channelSlug), label: t('appShellHeader.myChannel') }]
            : []),
      ],
      [
        {
          label: t('appShellHeader.myAccount'),
          href: Routes.account(),
        },
        {
          label: t('appShellHeader.signOut'),
          href: Routes.logout(),
        },
      ],
    ],
    [channelHandle, channelSlug, isChannelsV1Enabled, isEntriesPageEnabled, isUserVerified, t]
  );

  return (
    <>
      <Burger
        opened={isOpen}
        onClick={() => {
          onMenuToggle('user');
        }}
        color="white"
        className={classes.burgerButton}
        size={18}
        data-test-id={tc.components.appShellHeader.mobileMenuButton}
      />
      <HeaderDrawer
        isOpen={isOpen}
        onOpen={(v) => {
          onMenuOpen(v ? 'user' : undefined);
        }}
        transition="slide-left"
      >
        <div className={classes.userContainer}>
          <Group noWrap>
            <Avatar size={56} handle={user.username} src={avatarUrl} />
            <Stack spacing={0} sx={{ overflow: 'hidden' }}>
              <Text
                variant="headline-large"
                truncate
                data-test-id={tc.components.appShellHeader.username}
              >
                {user.username}
              </Text>
              <Text variant="body-large" truncate>
                {user.email}
              </Text>
              {typeof balance === 'number' && (
                <Text
                  variant="label-medium"
                  className={classes.balance}
                  data-test-id={tc.components.appShellHeader.balance}
                >
                  {CurrencyConvertor(balance)}
                </Text>
              )}
            </Stack>
          </Group>
          <div className={classes.depositAndWithdraw}>
            <ButtonLink
              href={Routes.withdraw(
                isBrowserFn()
                  ? { redirectTo: `${window.location.pathname}${window.location.search}` }
                  : undefined
              )}
              variant="primary-stroke"
              size="medium"
              onClick={() => {
                onMenuOpen();
              }}
            >
              {t('appShellHeader.withdraw')}
            </ButtonLink>
            <ButtonLink
              href={Routes.deposit(
                isBrowserFn()
                  ? { redirectTo: `${window.location.pathname}${window.location.search}` }
                  : undefined
              )}
              variant="primary-fill"
              size="medium"
              onClick={() => {
                onMenuOpen();
              }}
              data-test-id={tc.components.appShellHeader.depositButton}
            >
              {t('appShellHeader.deposit')}
            </ButtonLink>
          </div>
        </div>
        <Stack spacing="xs" p="sm">
          {navLinks.map((links, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {links.map(({ label, href }) => (
                <UnstyledNextLink
                  className={commonClasses.link}
                  href={href}
                  key={href}
                  onClick={() => {
                    onMenuOpen();
                  }}
                  data-test-id={tc.components.appShellHeader.menuItem}
                >
                  {label}
                </UnstyledNextLink>
              ))}
              {i < navLinks.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      </HeaderDrawer>
    </>
  );
}

export default MobileMenu;
