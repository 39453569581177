import React from 'react';

import { FEATURE } from '~/common/enums/feature.enum';

import { useTreatments } from '../../hooks/useTreatments';
import { Banner, BannerType } from '../Banner/Banner';

export function MaintenanceBanner() {
  const { isReady, treatments } = useTreatments([FEATURE.SHOW_MAINTENANCE_BANNER]);
  const { treatment, config } = treatments[FEATURE.SHOW_MAINTENANCE_BANNER];
  const isEnabled = treatment === 'on';

  if (!isReady || !isEnabled) {
    return null;
  }

  let message: string;
  let type: BannerType;
  if (process.env.STORYBOOK) {
    message = 'We are currently performing maintenance on our site. We will be back shortly.';
    type = BannerType.WARNING;
  } else {
    message = config?.message;
    type = config?.type ?? BannerType.WARNING;
  }

  if (!message) {
    return null;
  }

  return <Banner message={message} type={type} allowClose />;
}
