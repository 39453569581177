import { createStyles } from '@mantine/core';
import { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { ButtonLink } from '~/domains/design-system/Button';
import { Routes } from '~/domains/common/constants/routes';

import SitesMenu from '../common/SitesMenu/SitesMenu';
import Logo from '../common/Logo';
import { HeaderMenusContext } from '../HeaderMenusContext';
import { AppShellHeaderSearchButton } from '../AppShellHeaderSearchButton';

const useStyles = createStyles({
  rightSlot: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
});

function AppShellHeaderSignedOut() {
  const { t } = useTranslation('common');
  const { classes } = useStyles();
  const { onMenuOpen } = useContext(HeaderMenusContext);

  return (
    <>
      <SitesMenu />
      <Logo />
      <div className={classes.rightSlot}>
        <AppShellHeaderSearchButton />
        <ButtonLink
          variant="primary-fill"
          size="small"
          href={Routes.signIn({ redirectTo: window.location.pathname + window.location.search })}
          onClick={() => {
            onMenuOpen();
          }}
        >
          {t('appShellHeader.logIn')}
        </ButtonLink>
      </div>
    </>
  );
}

export default AppShellHeaderSignedOut;
