import type { FullContest } from '@betterpool/api-types';

import { getContestBanner } from '~/domains/contest/domains/detail/components/ContestHeader/utils/getContestBanner';

export const getContestPreviewImageData = (fullContest: Partial<FullContest>) => {
  const bannerUrl = getContestBanner({
    contestBannerlUrl: fullContest?.banner_url,
    sport: fullContest?.league?.sport,
  });

  const data = {
    bannerUrl,
    creator: fullContest?.commissioner.username,
    prize: fullContest?.prize_pool,
    deadline: fullContest?.start_date,
    entries: `${fullContest?.entries?.filled}/${fullContest?.entries?.max}`,
  };

  return data;
};
