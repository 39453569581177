export default {
  header: {
    editButton: 'Edit',
    shareButton: 'Share',
    copyLink: 'Copy link',
    followButton: 'Follow',
    followingButton: 'Following',
    inviteButton: 'Invite',
    followsYouTag: 'Follows you',
    numbers: {
      followers: 'Followers',
      following: 'Following',
      entrants: 'Entrants',
    },
  },
  edit: {
    avatar: 'Edit avatar',
    banner: 'Edit banner',
    name: 'Name',
    handle: 'Handle',
    description: 'Description',
    submit: 'Save changes',

    notifications: {
      success: {
        title: 'Channel updated 🎉',
        message: 'Your channel has been successfully updated.',
      },
      error: {
        title: 'Could not update channel',
        message: 'There was an error updating your channel. Please try again later.',
      },
    },
  },
  validation: {
    handleTaken: 'Handle is already taken',
  },
  notifications: {
    copySuccess: 'Link Copied',
    copyError: 'Could not copy URL: {{url}}',
    followSuccess: {
      title: 'Followed!',
      message: 'You are now following @{{handle}}',
    },
    followError: {
      title: 'Error following @{{handle}}',
    },
    unfollowSuccess: {
      title: 'Unfollowed!',
      message: 'You are not longer following @{{handle}}',
    },
    unfollowError: {
      title: 'Error unfollowing @{{handle}}',
    },
  },
  search: {
    placeholder: {
      title: 'Find players on Splash',
      text: 'Search for players by their @handle.',
    },
    noResults: {
      title: 'No results found',
      text: 'Try another @handle.',
    },
    minChars: {
      more: 'Please type {{charsRemaining}} more characters',
    },
  },
  contests: {
    create: 'Create a contest',
    title: {
      created: 'Join upcoming contests @{{handle}} created',
      entered: 'Join upcoming contests @{{handle}} entered',
    },
    tabs: {
      created: 'Created',
      entered: 'Entered',
    },
  },
  breadcrumbs: {
    home: 'Home',
    search: 'Search',
    myChannel: 'My Channel',
    invite: 'Invite',
  },
  newToSplash: {
    title: 'Log in to Splash Sports',
    text: 'Log in to follow and play real money games with friends.',
    logIn: 'Log in',
    signUp: 'Sign up',
  },
  channelSearch: {
    tabs: {
      followers: '{{number}} followers',
      following: '{{number}} following',
    },
  },
  invite: {
    title: 'Invite',
    subtitle: 'Select upcoming contests to send invites.',
    form: {
      validation: {
        contestIds: 'Please select at least one contest.',
      },
      submit: 'Send invites',
      success: {
        message__hasPlural: {
          one: 'You invited {{name}} to 1 contest!',
          other: 'You invited {{name}} to {{count}} contests!',
        },
      },
    },
    noContests: {
      title: 'No contests found',
      text: 'You do not have any upcoming contests available for sending invites.',
    },
  },
};
