import { ActionIcon, Box } from '@mantine/core';
import Link from 'next/link';

import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';
import { useNotificationsCount } from '~/domains/notifications/hooks/useNotifications';
import { IconNotification } from '~/domains/notifications/components/IconNotification';
import { Text } from '~/domains/design-system/Text';
import { formatChannelDetails } from '~/domains/channel/utils/formatChannelDetails';

import { Routes } from '../../constants/routes';

import NotificationBadge from './NotificationBadge';

export function AppShellHeaderNotificationsButton() {
  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);

  const { data, isLoading } = useNotificationsCount({ enabled: isChannelsV1Enabled });

  const count = data?.data?.count;

  if (!isChannelsV1Enabled) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <ActionIcon
      component={Link}
      href={Routes.notifications()}
      title="Notifications"
      size={28}
      sx={(theme) => ({
        position: 'relative',
        '&:hover': {
          background: theme.colors.gray[7],
        },
        svg: {
          path: { stroke: 'white' },
          width: 24,
          height: 24,
        },
      })}
    >
      {count ? (
        <Box sx={{ position: 'absolute', top: -4, right: -2 }}>
          <NotificationBadge>{formatChannelDetails(count)}</NotificationBadge>
        </Box>
      ) : null}
      <IconNotification />
    </ActionIcon>
  );
}
