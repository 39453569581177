export default {
  dfs: {
    playerCard: {
      abbrItem: 'contest-game-common-dfs-playerCard-abbrItem',
      container: 'contest-game-common-dfs-playerCard-container',
      header: 'contest-game-common-dfs-playerCard-header',
      info: 'contest-game-common-dfs-playerCard-info',
      playerName: 'contest-game-common-dfs-playerCard-playerName',
      remove: 'contest-game-common-dfs-playerCard-remove',
    },
    pickBar: {
      clearButton: 'contest-game-common-dfs-pickBar-clearButton',
      container: 'contest-game-common-dfs-pickBar-container',
      createEntryButton: 'contest-game-common-dfs-pickBar-createEntryButton',
      drafted: 'contest-game-common-dfs-pickBar-drafted',
      loginButton: 'contest-game-common-dfs-pickBar-loginButton',
      submitButton: 'contest-game-common-dfs-pickBar-submitButton',
      tierButton: 'contest-game-common-dfs-pickBar-tierButton',
      tierButtonIconCheck: 'contest-game-common-dfs-pickBar-tierButtonIconCheck',
    },
    pickGrid: {
      container: 'contest-game-common-dfs-pickGrid-container',
      subTitle: 'contest-game-common-dfs-pickGrid-sub-title',
      title: 'contest-game-common-dfs-pickGrid-title',
    },
    playerDetail: {
      container: 'contest-game-common-dfs-playerDetail-container',
      season: {
        container: 'contest-game-common-dfs-playerDetail-season-container',
        item: 'contest-game-common-dfs-playerDetail-season-item',
        items: 'contest-game-common-dfs-playerDetail-season-items',
      },
      action: 'contest-game-common-dfs-playerDetail-action',
    },
  },
  pickEm: {
    pickEmPicksPanel: {
      clearButton: 'contest-game-common-pickEm-pickEmPicksPanel-clearButton',
      collapseButton: 'contest-game-common-pickEm-pickEmPicksPanel-collapseButton',
      container: 'contest-game-common-pickEm-pickEmPicksPanel-container',
      item: {
        container: 'contest-game-common-pickEm-pickEmPicksPanel-item-container',
        teamNameText: 'contest-game-common-pickEm-pickEmPicksPanel-item-teamNameText',
        bestPickIcon: 'contest-game-common-pickEm-pickEmPicksPanel-item-bestPickIcon',
        shuffleButton: 'contest-game-common-pickEm-pickEmPicksPanel-item-shuffleButton',
      },
      missedPicksText: 'contest-game-common-pickEm-pickEmPicksPanel-missedPicksText',
      picksMadeText: 'contest-game-common-pickEm-pickEmPicksPanel-picksMadeText',
      submitButton: 'contest-game-common-pickEm-pickEmPicksPanel-submitButton',
    },
  },
  picksheet: {
    games: {
      container: 'contest-game-common-picksheet-games-container',
      teamName: 'contest-game-common-picksheet-games-teamName',
      item: 'contest-game-common-picksheet-games-item',
      upcoming: 'contest-game-common-picksheet-games-upcoming',
      final: 'contest-game-common-picksheet-games-final',
      live: 'contest-game-common-picksheet-games-live',
      standalone: 'contest-game-common-picksheet-games-standalone',
      tooltip: 'contest-game-common-picksheet-games-tooltip',
      noteam: 'contest-game-common-picksheet-games-noteam',
      seed: 'contest-game-common-picksheet-games-seed',
      action: {
        preview: 'contest-game-common-picksheet-action-preview',
      },
    },
    sort: 'contest-game-common-picksheet-sort',
    sortWrapper: 'contest-game-common-picksheet-wrapper',
    title: 'contest-game-common-picksheet-title',
  },
  header: {
    closeButton: 'contest-game-common-header-closeButton',
  },
  subHeader: {
    container: 'contest-game-common-subheader-container',
    date: 'contest-game-common-subheader-date',
    status: 'contest-game-common-subheader-status',
  },
  footer: {
    container: 'contest-game-common-footer-container',
    date: 'contest-game-common-footer-date',
    picks: 'contest-game-common-footer-picks',
  },
  aside: {
    closeButton: 'contest-game-common-aside-closeButton',
    container: 'contest-game-common-aside-container',
  },
  modal: {
    makePicksModal: {
      closeButton: 'contest-game-common-modal-makePicksModal-closeButton',
      container: 'contest-game-common-modal-makePicksModal-container',
      inviteButton: 'contest-game-common-modal-makePicksModal-inviteButton',
      linkButton: 'contest-game-common-modal-makePicksModal-linkButton',
      primaryButton: 'contest-game-common-modal-makePicksModal-primaryButton',
    },
  },
};
