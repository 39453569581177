import { Group, useMantineTheme, Flex } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import React from 'react';
import { isPast } from 'date-fns';
import { ContestStatus } from '@betterpool/api-types/contests-service';

import ContestCountDown from '~/components/utils/ContestCountdown';
import { BadgeIcons } from '~/components/shared/Badges/Badges';
import { Avatar } from '~/domains/design-system/Avatar';
import { LinkNew } from '~/domains/common/components/Link/Link';
import { Routes } from '~/domains/common/constants/routes';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import type { MyUserContest } from '~/domains/contest/domains/myContests/types/userContest';
import { Title } from '~/domains/design-system/Title';
import { Text } from '~/domains/design-system/Text';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';

import ContestTypeBadge from '../ContestTypeBadge/ContestTypeBadge';
import { getContestThumbnail } from '../../utils/getContestThumbnail';

import ContestCardMenu from './ContestCardMenu';
import useStyles from './ContestCard.styles';

type ContestCardHeaderProps = {
  contest: Contest | MyUserContest;
  isContestThumbnailShown?: boolean;
  isBadgeIconSectionEnabled?: boolean;
  isCardMenuVisible?: boolean;
  isCommissionerLinkEnabled?: boolean;
};

function ContestCardHeader({
  contest,
  isContestThumbnailShown,
  isBadgeIconSectionEnabled = true,
  isCardMenuVisible = true,
  isCommissionerLinkEnabled = true,
}: ContestCardHeaderProps) {
  const theme = useMantineTheme();
  const { classes } = useStyles({});

  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);

  return (
    <Group position="apart" className={classes.top}>
      <Group noWrap className={classes.topLeftSlot}>
        {isContestThumbnailShown && (
          <Avatar
            // TODO: update username to handle
            handle={contest.commissioner.handle || contest.commissioner.username}
            size="md"
            src={getContestThumbnail({
              contestThumbnailUrl: contest.thumbnail_url,
              createdByAdmin: contest.created_by_admin,
              commissionerId: contest.commissioner.id,
            })}
          />
        )}

        <div className={classes.titleWrapper}>
          <Title
            tag="h4"
            variant="headline-medium"
            truncate
            maw={{ base: '100%', md: 310 }}
            w={{ base: 230, xs: '100%' }}
          >
            {upperFirst(contest.name)}
          </Title>
          <Flex gap={4} wrap={{ base: 'wrap', md: 'nowrap' }} align="center">
            {contest.commissioner ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {isCommissionerLinkEnabled ? (
                  <LinkNew
                    onClick={(e) => e.stopPropagation()}
                    href={
                      isChannelsV1Enabled && contest.commissioner.handle
                        ? Routes.channel(contest.commissioner.handle)
                        : Routes.commissionerChannel(contest.commissioner.id)
                    }
                    sx={{ color: theme.colors.gray[6] }}
                  >
                    <Text component="span" size={theme.fontSizes.xs} display="block" truncate>
                      {contest.commissioner.username
                        ? `@${contest.commissioner.handle || contest.commissioner.username}`
                        : 'Unnamed'}
                    </Text>
                  </LinkNew>
                ) : (
                  <Text component="span" size={theme.fontSizes.xs} display="block" truncate>
                    {contest.commissioner.username
                      ? `@${contest.commissioner.handle || contest.commissioner.username}`
                      : 'Unnamed'}
                  </Text>
                )}
              </>
            ) : null}

            <Text color={theme.colors.gray[6]} variant="body-small">
              •
            </Text>
            <Text color={theme.colors.gray[6]} variant="body-small">
              {(() => {
                switch (contest.status) {
                  case ContestStatus.SCHEDULED: {
                    if (!contest.start_date) return null;
                    return (
                      <ContestCountDown
                        color={theme.colors.gray[6]}
                        fontSize={theme.fontSizes.xs}
                        contestStart={contest.start_date.toString()}
                      />
                    );
                  }
                  case ContestStatus.IN_PROGRESS: {
                    if (!('pick_lock_date' in contest)) {
                      return null;
                    }

                    return (
                      <>
                        {isPast(contest.pick_lock_date) ? 'Locked: ' : 'Locks: '}
                        <ContestCountDown
                          color={theme.colors.gray[6]}
                          fontSize={theme.fontSizes.xs}
                          contestStart={contest.pick_lock_date}
                        />
                      </>
                    );
                  }
                  case ContestStatus.FINALIZED:
                  case ContestStatus.FINISHED: {
                    if (!('settled_date' in contest)) {
                      return null;
                    }

                    return (
                      <>
                        Completed:{' '}
                        <ContestCountDown
                          color={theme.colors.gray[6]}
                          fontSize={theme.fontSizes.xs}
                          contestStart={contest.settled_date}
                        />
                      </>
                    );
                  }
                  default: {
                    return null;
                  }
                }
              })()}
            </Text>
            <Text color={theme.colors.gray[4]} variant="body-small">
              •
            </Text>
            {isBadgeIconSectionEnabled ? (
              <BadgeIcons wrapStyle="" tags={contest.tags} iconSize={20} />
            ) : null}
            <ContestTypeBadge type={contest.contest_type} />
          </Flex>
        </div>
      </Group>
      {isCardMenuVisible ? (
        <div data-ignore-link className={classes.menu}>
          <ContestCardMenu contest={contest} />
        </div>
      ) : null}
    </Group>
  );
}

export default ContestCardHeader;
