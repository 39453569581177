import authComponents from '../components/shared/Auth/testing.const';
import common from '../domains/common/components/testing.const';
import contestDetail from '../domains/contest/domains/detail/constants/testing.const';
import contestLobby from '../domains/contest/domains/lobby/constants/testing.const';
import deposits from '../domains/payments/deposits/testing.const';
import header from '../components/shared/Header/testing.const';
import kyc from '../domains/kyc/testing.const';
import lobby from '../components/lobby/testing.const';
import paymentsComponents from '../domains/payments/components/testing.const';
import withdrawal from '../domains/payments/withdrawal/testing.const';
import user from '../domains/user/testing.const';
import myContests from '../domains/contest/domains/myContests/testing.const';
import contestGameCommon from '../domains/contest/domains/game/domains/common/testing.const';
import contestCreate from '../domains/contest/domains/create/testing.const';
import contestCommon from '../domains/contest/domains/common/testing.const';
import contestGamecenter from '../domains/contest/domains/gamecenter/testing.const';
import auth from '../domains/auth/testing.const';
import myAccount from '../components/myAccount/testing.const';

export default {
  common,
  auth,
  components: {
    lobby,
    shared: {
      auth: authComponents,
      header,
    },
  },
  myContests,
  contestDetail,
  contestLobby,
  user,
  kyc,
  payments: {
    components: paymentsComponents,
    withdrawal,
    deposits,
  },
  contest: {
    create: contestCreate,
    game: {
      common: contestGameCommon,
    },
    gamecenter: contestGamecenter,
    common: contestCommon,
  },
  myAccount,
};
