/**
 * WARNING:
 * Do not import anything into this file from other domains.
 * Otherwise you will risk a circular dependency.
 * */
import { ContestStatus, ContestType, Sport } from '@betterpool/api-types/contests-service';

import { CommishContestTabValues, CommishTabValues } from '~/domains/commissioner/console/enum';
import type {
  AsideContentType,
  ContentURLType,
} from '~/domains/contest/domains/game/domains/common/types/aside';
import type { ContestStatusFilter } from '~/domains/contest/domains/services/enums/contest-status-filter.enum';

import { URLQueryKeys } from '../types/URLQueryKeys';
import { createQueryString } from '../utils/queryString';

const routeWithQuery =
  <P extends Record<string, string | number | boolean>>(route: string) =>
  (queryParams?: P) => {
    const queryString = queryParams ? createQueryString(queryParams) : '';
    return `${route}${queryString}`;
  };

type ActiveTab = {
  [URLQueryKeys.ACTIVE_TAB]?: string;
};

type Redirect = {
  [URLQueryKeys.REDIRECT_TO]?: string;
  [URLQueryKeys.CONTEST_ID]?: string;
  [URLQueryKeys.REF_PLATFORM]?: string;
  [URLQueryKeys.REF]?: string;
};

type ContestDetailOptions = {
  [URLQueryKeys.REF]?: string;
  [URLQueryKeys.UTM_CAMPAIGN]?: string;
  [URLQueryKeys.UTM_CONTENT]?: string;
  [URLQueryKeys.UTM_SOURCE]?: string;
  [URLQueryKeys.UTM_MEDIUM]?: string;
  [URLQueryKeys.ENTER_CONTEST]?: boolean;
};

type SearchQuery = {
  [URLQueryKeys.SEARCH_QUERY]?: string;
};

export const Routes = {
  search: (q?: string) => routeWithQuery<SearchQuery>('/search')({ q }),
  notifications: () => '/notifications',
  channel: (handle: string, q?: string) => routeWithQuery<SearchQuery>(`/channel/${handle}`)({ q }),
  channelEdit: (handle: string) => `/channel/${handle}/edit`,
  invite: (handle: string, q?: string) =>
    routeWithQuery<SearchQuery>(`/channel/${handle}/invite`)({ q }),
  channelSearch: (
    handle: string,
    activeTab?: URLQueryKeys.CHANNEL_FOLLOWERS | URLQueryKeys.CHANNEL_FOLLOWING,
    q?: string
  ) => routeWithQuery<ActiveTab & SearchQuery>(`/channel/${handle}/search`)({ q, activeTab }),
  /** @deprecated - use channels instead */
  commissionerChannel: (channelSlug_or_commissionerUserId: string) =>
    `/commissioner/channel/${channelSlug_or_commissionerUserId}`,
  /** @deprecated - use channels instead */
  commissionerChannelUpdate: (channelSlug: string) => `/commissioner/channel/${channelSlug}/update`,
  /** @deprecated - use channels instead */
  commissionerHub: (
    channelSlug: string,
    activeTab: ActiveTab = { activeTab: ContestStatus.IN_PROGRESS }
  ) => routeWithQuery<ActiveTab>(`/commissioner/hub/${channelSlug}`)(activeTab),
  /** @deprecated - use channels instead */
  commissionerConsole: (tab?: CommishTabValues) =>
    `/commissioner/console${tab ? `/${tab}` : `/${CommishTabValues.HOME}`}`,
  /** @deprecated - use channels instead */
  contestInsights: (contestId: string, tab?: CommishContestTabValues) =>
    `/commissioner/console/${CommishTabValues.CONTESTS}/${contestId}${
      tab ? `/${tab}` : `/${CommishContestTabValues.OVERVIEW}`
    }`,
  commissionerOnboarding: () => '/commissioner/onboarding',
  createAContest: routeWithQuery<Redirect>('/contest/create'),
  editAContest: (contestId: string) => routeWithQuery<Redirect>(`/contest/${contestId}/edit`)(),

  gameshell: ({
    contestId,
    sport,
    contestType,
    isSalary,
  }: {
    contestId: string;
    sport: Sport;
    contestType: ContestType;
    isSalary?: boolean;
  }) => {
    const parsedSport = sport?.replace(/_/g, '-');
    const parsedContestType = contestType?.replace(/_/g, '-');

    return `/contest/${contestId}/game/${parsedSport}/${parsedContestType}${
      isSalary ? '/salary' : ''
    }`;
  },
  gameshellPicksheet: ({
    contestId,
    sport,
    contestType,
    entryId,
    referrer,
    slateId,
    editingPicks,
    isSalary,
    isNewGameshell,
    isNewEntry,
    aside,
  }: {
    contestId: string;
    sport: Sport;
    contestType: ContestType;
    entryId?: string;
    referrer?: string;
    slateId?: string;
    editingPicks?: boolean;
    isSalary?: boolean;
    isNewGameshell?: boolean;
    isNewEntry?: boolean;
    aside?: AsideContentType;
  }) => {
    const oldGameshellPath = routeWithQuery<{
      [URLQueryKeys.ENTRY_ID]?: string;
      [URLQueryKeys.SLATE_ID]?: string;
      [URLQueryKeys.REF]?: string;
      [URLQueryKeys.EDITING]?: string;
      [ContentURLType.ASIDE]?: AsideContentType;
    }>(`/contest/${contestId}/game`)({
      entryId,
      slateId,
      ref: referrer,
      aside,
      editingPicks: editingPicks ? 'true' : undefined,
    });
    const newGameshellBasePath = Routes.gameshell({
      contestId,
      sport,
      contestType,
      isSalary,
    });
    const newGameshellPath = routeWithQuery<{
      [URLQueryKeys.SLATE_ID]?: string;
      [URLQueryKeys.ENTRY_ID]?: string;
      [URLQueryKeys.REF]?: string;
      [URLQueryKeys.IS_NEW_ENTRY]?: boolean;
      [URLQueryKeys.UTM_PARAM_CONTEST_ID]?: string;
      [URLQueryKeys.UTM_CAMPAIGN]?: ContestType;
      [URLQueryKeys.UTM_MEDIUM]?: string;
      [ContentURLType.ASIDE]?: AsideContentType;
    }>(`${newGameshellBasePath}/pick`)({
      slateId,
      entryId,
      ref: referrer,
      utm_param_contest_id: contestId,
      utm_medium: 'picksheet',
      utm_campaign: contestType,
      aside,
      ...(isNewEntry && { isNewEntry }),
    });

    switch (sport) {
      case Sport.FOOTBALL:
      case Sport.BASKETBALL: {
        // TODO: Once we get rid off old gameshell we can remove this condition
        // for SportTag.BASKETBALL and SportTag.FOOTBALL
        if (
          contestType === ContestType.PLAYER_TIER &&
          sport === Sport.FOOTBALL &&
          !isNewGameshell
        ) {
          return oldGameshellPath;
        }

        return newGameshellPath;
      }
      case Sport.GOLF: {
        // TODO: Once we get rid off old gameshell we can remove this condition for SportTag.GOLF
        if (contestType === ContestType.PLAYER_ONE_AND_DONE) {
          return newGameshellPath;
        }

        return isNewGameshell ? newGameshellPath : oldGameshellPath;
      }
      default:
        return isNewGameshell ? newGameshellPath : oldGameshellPath;
    }
  },
  contestDetail: (contestId: string, options?: ContestDetailOptions) =>
    routeWithQuery<ContestDetailOptions>(`/contest/${contestId}/detail`)(options),
  contestPosts: (contestId: string) => `/contest/${contestId}/posts`,
  signIn: routeWithQuery<Redirect>('/sign-in'),
  signUp: routeWithQuery<Redirect>('/sign-up'),
  home: () => '/home',
  main: () => '/',
  logout: () => '/logout',
  myEntries: routeWithQuery<{
    [URLQueryKeys.CONTEST_TYPE]?: ContestType;
    [URLQueryKeys.ENTRY_STATUS]?: ContestStatusFilter;
  }>('/my-entries'),
  entries: routeWithQuery<{
    [URLQueryKeys.CONTEST_TYPE]?: ContestType;
    [URLQueryKeys.CONTEST_STATUS]?: ContestStatusFilter;
  }>('/entries'),
  myContests: routeWithQuery<{
    [URLQueryKeys.CONTEST_TYPE]?: ContestType;
    [URLQueryKeys.CONTEST_STATUS]?: ContestStatusFilter;
  }>('/my-contests'),
  contestLobby: routeWithQuery<{
    [URLQueryKeys.CONTEST_TYPE]?: ContestType;
    [URLQueryKeys.LEAGUE]?: string;
  }>('/contest-lobby'),
  account: () => '/account',
  forgotPassword: () => '/account/forgot-password',
  deposit: routeWithQuery<Redirect>('/account/wallet/deposit'),
  withdraw: routeWithQuery<Redirect>('/account/wallet/withdraw'),
  userVerification: routeWithQuery<Redirect>('/user-verification'),
  userVerificationAccept: routeWithQuery<Redirect>('/user-verification/accept'),
  appSplashContestDetailRedirect: (id: string) =>
    `${process.env.NEXT_PUBLIC_NATIVE_APP_VIPP}://contest?id=${id}`,
  termsOfUse: (isCanada) =>
    isCanada
      ? 'https://legal.splashsports.com/legal/splash-sports-terms-of-use-canada'
      : 'https://legal.splashsports.com/legal/terms-of-use',
  privacyPolicy: (isCanada) =>
    isCanada
      ? 'https://legal.splashsports.com/legal/splash-sports-privacy-policy-canada'
      : 'https://legal.splashsports.com/legal/privacy-policy',
  gamecenter: ({
    contestId,
    isAfterEntryDeadline,
    userEntriesCount,
    promoSource,
    promoIndex,
  }: {
    contestId: string;
    isAfterEntryDeadline: boolean;
    userEntriesCount: number;
    promoSource?: string;
    promoIndex?: number;
  }) => {
    if (userEntriesCount > 0) {
      return isAfterEntryDeadline
        ? Routes.gamecenterStandingsOverall(contestId)
        : Routes.gamecenterEntriesOverall(contestId);
    }

    return Routes.contestDetail(contestId, {
      [URLQueryKeys.UTM_SOURCE]: promoSource,
      [URLQueryKeys.UTM_CAMPAIGN]: `${promoIndex}`,
    });
  },
  gamecenterStandingsOverall: (
    contestId: string,
    {
      entryId,
      entrySlateId,
      entryUsername,
    }: { entryId?: string; entrySlateId?: string; entryUsername?: string } = {}
  ) =>
    `/contest/${contestId}/standings/overall${createQueryString({ entryId, entrySlateId, entryUsername })}`,
  gamecenterStandingsSlates: (
    contestId: string,
    {
      entryId,
      slateId,
      entryUsername,
      entrySlateId,
    }: { entryId?: string; slateId?: string; entryUsername?: string; entrySlateId?: string } = {}
  ) =>
    `/contest/${contestId}/standings/slates${createQueryString({ entryId, slateId, entryUsername, entrySlateId })}`,
  gamecenterEntriesOverall: (contestId: string) => `/contest/${contestId}/entries/overall`,
  gamecenterEntriesSlates: (
    contestId: string,
    { entryId, slateId }: { entryId?: string; slateId?: string } = {}
  ) => `/contest/${contestId}/entries/slates${createQueryString({ entryId, slateId })}`,
  gamecenterPicks: (
    contestId: string,
    {
      entryId,
      slateId,
    }: {
      entryId?: string;
      slateId?: string;
    } = {}
  ) => `/contest/${contestId}/picks${createQueryString({ entryId, slateId })}`,
  gamecenterStats: (contestId: string) => `/contest/${contestId}/stats/slate-summary`,
  gamecenterStatsAvailability: (contestId: string) => `/contest/${contestId}/stats/availability`,
  appDownloadPrompt: routeWithQuery<Redirect>('/app-download'),
  sutError: () => '/sut/error',
  quickPicks: () => '/quick-picks',
};
