export default {
  enterContestModal: {
    modal: 'contest-common-enterContestModal-modal',
    title: 'contest-common-enterContestModal-title',
    action: {
      decrement: 'contest-common-enterContestModal-action-decrement',
      increment: 'contest-common-enterContestModal-action-increment',
      numberOfEntries: 'contest-common-enterContestModal-action-numberOfEntries',
      createEntries: 'contest-common-enterContestModal-action-createEntries',
      depositToEnter: 'contest-common-enterContestModal-action-depositToEnter',
      cancel: 'contest-common-enterContestModal-cancel',
    },
  },
};
