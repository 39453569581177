import React from 'react';
import { Title, Text } from '@mantine/core';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

import useStyles from './FullScreenMessage.styles';

type FullScreenMessageProps = {
  title?: JSX.Element | string;
  subtitle?: JSX.Element | string;
  icon?: JSX.Element;
};

export function FullScreenMessage({ title, subtitle, icon }: FullScreenMessageProps) {
  const isMobile = useIsMobile();
  const { classes } = useStyles({ isMobile });

  return (
    <div className={classes.container}>
      {icon && <div className={classes.iconWrapper}>{icon}</div>}
      {title && (
        <Title size={isMobile ? 'h4' : 'h1'} maw="500px">
          {title}
        </Title>
      )}
      {subtitle && (
        <Text size={isMobile ? 'sm' : 'md'} maw="400px">
          {subtitle}
        </Text>
      )}
    </div>
  );
}
