export default {
  components: {
    kyc: {
      status: {
        continueButton: 'domains-kyc-components-kyc-status-continueButton',
      },
    },
  },
  form: {
    kyc: {
      stepOne: {
        firstNameInput: 'domains-kyc-form-kyc-stepOne-firstNameInput',
        lastNameInput: 'domains-kyc-form-kyc-stepOne-lastNameInput',
      },
      dateOfBirth: {
        month: 'domains-kyc-form-kyc-dateOfBirth-month',
        day: 'domains-kyc-form-kyc-dateOfBirth-day',
        year: 'domains-kyc-form-kyc-dateOfBirth-year',
      },
      actions: {
        continue: 'domains-kyc-form-kyc-actions-continue',
      },
      stepTwo: {
        addressMainInput: 'domains-kyc-form-kyc-stepTwo-addressMainInput',
        addressOptionalInput: 'domains-kyc-form-kyc-stepTwo-addressOptionalInput',
        cityInput: 'domains-kyc-form-kyc-stepTwo-cityInput',
        stateInput: 'domains-kyc-form-kyc-stepTwo-stateInput',
        zipCodeInput: 'domains-kyc-form-kyc-stepTwo-zipCodeInput',
        countrySelect: 'domains-kyc-form-kyc-stepTwo-countrySelect',
        mobileNumberInput: 'domains-kyc-form-kyc-stepTwo-mobileNumberInput',
      },
    },
  },
};
