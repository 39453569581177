import log, { logError } from '~/common/utils/log';
import { isBrowserFn } from '~/domains/analytics/utils';

import renderSlideUpMessage from './slideUpMessage';
import type { BrazeInstance } from './types';
import { isControlMessage, isSlideUpMessage, isModalMessage } from './types';

class BrazeSingleton {
  private static instance: BrazeInstance;

  private static activeUserId: string;

  private static async initBraze() {
    if (!process.env.NEXT_PUBLIC_BRAZE_SDK_KEY || !process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT) {
      logError('Braze: No SDK key or endpoint found!');
      return;
    }

    try {
      const brazeInstance = await import(
        /*
         * Braze SDK is broken in SSR, so we need to import it dynamically
         * Using webpackExports magic with to get around "ReferenceError: navigator is not defined"
         * Do nor mess with the async methods unless you know what you're doing!
         */
        /* webpackExports: ["changeUser", "initialize", "logInAppMessageClick", "openSession", "setLogger", "subscribeToInAppMessage", "showInAppMessage"] */
        '@braze/web-sdk'
      );

      await brazeInstance.initialize(process.env.NEXT_PUBLIC_BRAZE_SDK_KEY, {
        baseUrl: process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT,
        enableLogging: process.env.NEXT_PUBLIC_DEBUG === 'true',
        appVersion: process.env.VERSION,

        // TODO: Figure font-awesome implementation once we need to support icons
        doNotLoadFontAwesome: true,
        allowUserSuppliedJavascript: false,
        noCookies: true,
      });

      // Subscribe to receive in-app messages
      await brazeInstance.subscribeToInAppMessage((inAppMessage) => {
        // Control group messages should always be "shown" by default Braze UI
        // This will log an impression and not show a visible message
        if (isControlMessage(inAppMessage)) {
          return brazeInstance.showInAppMessage(inAppMessage);
        }

        // Use custom render method for slide-up messages
        if (isSlideUpMessage(inAppMessage)) {
          renderSlideUpMessage(brazeInstance, inAppMessage);
        }

        // Use default Braze UI to show modal messages
        // TODO: Handle modal messages with internal renderer as well
        if (isModalMessage(inAppMessage)) {
          brazeInstance.showInAppMessage(inAppMessage);
        }
      });

      brazeInstance.setLogger(log);
      brazeInstance.openSession();

      return brazeInstance;
    } catch (error) {
      logError(error);
    }
  }

  private static async getInstance() {
    if (!this.instance && isBrowserFn) {
      const instance = await this.initBraze();
      this.instance = instance;
    }

    return this.instance;
  }

  public static async start() {
    await this.getInstance();

    return true;
  }

  public static async setUserId(userId: string) {
    if (!userId || userId === this.activeUserId) return;
    const instance = await this.getInstance();

    this.activeUserId = userId;
    return instance?.changeUser(userId);
  }

  public static async stop() {
    const instance = await this.getInstance();

    this.activeUserId = null;
    return instance?.destroy();
  }
}

export default BrazeSingleton;
